<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({ name: { label: 'Seal Graphic With Text', group: 'global' }, fields: { topText: { type: 'text', label: 'Top Text', default: 'FREE', name: 'topText' }, largeText: { type: 'text', label: 'Large Text', default: 3, name: 'largeText' }, bottomText: { type: 'text', label: 'Bottom Text', default: 'NIGHT STAY FOR TWO', name: 'bottomText' } }, slots: [] })
  },
}
</script>

<template>
  <div class="text-center relative min-h-[300px]">
    <img
      src="https://playa-cms-assets.s3.amazonaws.com/media/General_Assets/blue-seal.png"
      alt="Blue Seal"
      width="100%"
      height="auto"
      class="max-w-[300px] absolute absolute-center z-0 cursor-events-none w-full"
    >
    <div class="mx-auto max-w-[300px] z-1 relative p-10">
      <p class="title font-bold text-2xl pt-4">
        {{ fields.topText.value }}
      </p>
      <p class="sans-montserrat title font-bold text-8xl my-0 py-0" style="line-height: .8em;">
        {{ fields.largeText.value }}
      </p>
      <p class="title font-bold text-2xl px-10" style="line-height: 1.5em">
        {{ fields.bottomText.value }}
      </p>
    </div>
  </div>
</template>
