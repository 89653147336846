<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage })
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Html  Video', group: 'global' },
      fields: {
        legacyVimeoId: {
          type: 'text',
          label: 'Legacy Vimeo ID',
          name: 'legacyVimeoId',
        },
        poster: {
          type: 'media',
          label: 'Poster Image',
          breakpoints: { lg: { width: 1280, height: 720 } },
          name: 'poster',
        },
        maxWidth: {
          type: 'number',
          label: 'Max Width',
          default: '1080',
          enabled: true,
          name: 'maxWidth',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="flex justify-center items-center px-8 py-8">
    <videos-inline-html5-video :devise="devise" />
  </div>
</template>
