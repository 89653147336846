<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    isFullScreen() {
      if (this.$voix.breakpoint === 'default' || this.$voix.breakpoint === 'sm')
        return false

      return true
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Hero Slider', group: 'global' },
      preview: 'SlicesGlobalHeroesHeroSlider.jpg',
      fields: {
        showWaves: { type: 'checkbox', label: 'Show Waves?', default: false, name: 'showWaves' },
        offsetHeight: { type: 'number', label: 'Offset Height', enabled: false, name: 'offsetHeight' },
      },
      slots: [{ name: 'default', allowedElements: ['Vimeo Hero', 'Slider Image'] }],
    })
  },
}
</script>

<template>
  <div class="w-full relative" style="min-height: 100px">
    <div
      v-if="currentPage.site_id === 7"
      class="absolute top-0 left-0 p-8 w-full h-full z-10 pointer-events-none"
    >
      <div class="border-white border-4 w-full h-full" />
    </div>
    <general-swiper v-if="slice.elements.length >= 2" class="w-full" :looping="true">
      <VoixSlice v-for="s in slice.elements" :key="s.id" :slice="s" />
    </general-swiper>
    <div v-else>
      <VoixSlice v-for="s in slice.elements" :key="s.id" :slice="s" />
    </div>
    <div
      v-if="fields.showWaves.value"
      class="w-full relative hidden md:block"
      style="height: 0; margin-top: -75px; padding-bottom: 100px"
    >
      <svg
        width="100%"
        height="115"
        class="absolute left-0 top-0 z-10"
        viewBox="0 0 1287 77"
        preserveAspectRatio="none"
      >
        <path
          fill="#fff"
          d="M0,3c0,0,13,7,30,8c0,0,433,12,512,5c0,0,136-4,159-9c0,0,306,10,350-4l36-3c0,0,178,10,200,2v75H0V3z"
        />
      </svg>
    </div>
  </div>
</template>
