<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    iframeStyles() {
      return {
        height: `${this.fields.height.value}px`,
      }
    },

  },
  mounted() {
    defineSlice({ name: { label: 'Iframe', group: 'global' }, fields: { iframeUrl: { type: 'text', label: 'IFrame URL', name: 'iframeUrl' }, height: { type: 'text', label: 'IFrame Height', default: '2500', name: 'height' } }, slots: [] })
  },
}
</script>

<template>
  <iframe
    allowfullscreen
    frameborder="0"
    scrolling="no"
    seamless="seamless"
    class="w-full"
    :src="fields.iframeUrl.value"
    :style="iframeStyles"
  />
</template>
