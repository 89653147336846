<script>
export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },

    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Credibility Slider', group: 'global' },
      preview: 'SlicesGlobalCredibilityCredibilitySlider.jpg',
      fields: { title: { type: 'text', label: 'Title', editorLabel: true, default: 'Resort Awards', name: 'title' } },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div
    class="flex justify-center z-10 relative overflow-hidden credibility-slider"
  >
    <div
      class="md:p-12 md:px-10 py-8 container text-center flex flex-col items-center"
    >
      <h4
        class="md:px-2 z-10 bg-abs-white uppercase text-playa-action run-through"
        v-html="fields.title.value"
      />

      <div
        class="relative mt-8 shadow-lg md:w-4/5  md:py-4 border-b border-t "
      >
        <GeneralSwiper :looping="true" :pagination="false" :slides-per-view="5" :space-between="80" arrow-color="#666" class="px-12 pb-8">
          <VoixSlice
            v-for="s in slice.elements"
            :key="s.id"
            :slice="s"
            :fields="s.fields"
            class="w-32"
          />
        </GeneralSwiper>
      </div>
    </div>
  </div>
</template>
