<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsPackagesSlide.jpg',
      description: 'Weddings Packages Slide',
      fields: {
        background: {
          type: 'media',
          label: 'Background Image',
          breakpoints: {
            default: {
              width: 1600,
            },
          },
        },
        slideTitle: {
          type: 'text',
          label: 'Title',
          default: 'Big Day Preview',
        },
        description: {
          type: 'wysiwyg',
          label: 'Description',
          default: '<p style="text-align: center;font-size:.9em">Before confirming your date, preview your big day in paradise during the ultimate escape at Hyatt Zilara Riviera Cancun. With Big Day Preview, the two of you will enjoy two days of all inclusive luxury at Hyatt Zilara Riviera Cancun for just $500, and you\'ll then receive a $500 credit toward your wedding upon booking. IT\'s like getting your preview for free!</p>',
        },
        learnMore: {
          type: 'wysiwyg',
          label: 'Learn More Modal',
          enabled: false,
        },
        link: {
          type: 'link',
          label: 'Link',
        },
      },
      name: {
        label: 'Weddings Packages Slide',
        group: 'Weddings',
      },
      templates: [],
    })
  },
}
</script>

<template>
  <div class="w-full mx-auto items-center">
    <VoixMedia v-slot="slotProps" :field="fields.background" background>
      <div :style="{ backgroundImage: `url(${slotProps.image})` }" class="pt-64 bg-cover">
        <div
          class="mx-auto w-full md:w-[800px] rounded-b rounded-full p-10 md:p-2 items-center"
          style="background-color: rgba(255, 255, 255, 0.8);"
        >
          <div class="mx-auto text-center w-full md:w-2/3 p-10 md:p-8 font-sans-montserrat text-sm">
            <h2
              class="text-center font-cursive font-light text-gray-600 text-5xl md:text-6xl leading-[0.75em]"
            >
              {{ fields.slideTitle?.value }}
            </h2>
            <div class="my-4 text-sm" v-html="fields.description?.value" />

            <a
              v-if="!fields.learnMore?.enabled" :href="fields.link?.value?.href" :target="fields.link?.value?.target"
              class="uppercase mx-auto rounded-full bg-hyatt-wedding-action px-6 py-2 text-sm text-abs-white"
            >
              {{ fields.link?.value?.text }}
            </a>

            <LearnMore v-if="fields.learnMore?.enabled" :learn-more="fields.learnMore.value">
              <button
                class="uppercase mx-auto rounded-full bg-hyatt-wedding-action px-6 py-2 text-sm text-abs-white"
              >
                {{ fields.link?.value?.text }}
              </button>
            </LearnMore>
          </div>
        </div>
      </div>
    </VoixMedia>
  </div>
</template>
