
<template>
    <zilara-its-all-included :devise="devise" />

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  data () {
      return {
      }
  },
  computed: {
    currentPage () {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise () {
      return Object.assign({},{ fields: this.fields, page: this.currentPage, slice: this.slice})
    },

  },
  mounted () {
      defineSlice({"fields":{"title":{"type":"text","label":"Title","editorLabel":true,"default":"\"All Included\""},"copy":{"type":"wysiwyg","label":"Copy under title"},"includedItems":{"type":"wysiwyg","label":"Included Items"},"hideCheckIcon":{"type":"checkbox","label":"Hide Check Icon","default":false}},"name":{"label":"All Included","group":"Hyatt"},"templates":[{"label":"All Included","fields":{"copy":{"value":"<p>All-inclusive should exceed expectations. That\u2019s why Resorts by Hyatt guests can revel in unexpected luxuries. Stunning oceanfront settings, vibrant culture, live entertainment and so much more\u2014it\u2019s always included.<\/p>"},"includedItems":{"value":"<ul><li><p>Unlimited Dining<\/p><\/li><li><p>Endless Drinks<\/p><\/li><li><p>Restaurants<\/p><\/li><li><p>Bars &amp; Lounges<\/p><\/li><li><p>Premium Top Shelf Wines &amp; Liquors<\/p><\/li><li><p>Oceanfront Dining<\/p><\/li><li><p>24-hour Room Service<\/p><\/li><\/ul><ul><li><p>Free Wi-Fi<\/p><\/li><li><p>Minibar stocked daily<\/p><\/li><li><p>Non-Motorized Watersports<\/p><\/li><li><p>Water Volleyball<\/p><\/li><li><p>Aqua Gym Class<\/p><\/li><li><p>Live Entertainment<\/p><\/li><li><p>Double Jetted Tub<\/p><\/li><li><p>Romance Concierge<\/p><\/li><\/ul>"}}}]})
  },
};
</script>