<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    textColorClass() {
      return {
        'text-sanctuary-primary': (this.fields.style.value === 'light'),
        'text-white font-light': (this.fields.style.value === 'dark'),
      }
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Title Paragraph',
        group: 'Sanctuary',
        layouts: ['Sanctuary2024'],
      },
      preview: 'SlicesSanctuaryIntroductionsTitleParagraph.jpg',
      fields: {
        title: {
          type: 'text',
          label: 'Title',
          group: 'Title',
          editorLabel: true,
          enabled: true,
        },
        titleTag: {
          type: 'select',
          label: 'Title Tag',
          group: 'Title',
          options: { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6' },
          default: 'h3',
        },
        titleSize: {
          type: 'select',
          label: 'Title Size',
          group: 'Title',
          options: {
            'text-sm': 'Small',
            'text-base': 'Base',
            'text-lg': 'Large',
            'text-xl': 'XL',
            'text-2xl': '2XL',
            'text-3xl': '3XL',
            'text-4xl': '4XL',
            'text-5xl': '5XL',
          },
          default: 'text-3xl',
        },

        illustration: {
          label: 'Illustration',
          type: 'select',
          enabled: true,
          options: { none: 'None', seahorse: 'Seahorse', sisserou: 'Sisserou', turtle: 'Turtle' },
        },

        introduction: {
          type: 'wysiwyg',
          label: 'Introduction',
          group: 'Introduction',
        },
        introductionSize: {
          type: 'select',
          label: 'Introduction Size',
          group: 'Introduction',
          options: { 'text-sm': 'Small', 'text-base': 'Base', 'text-lg': 'Large', 'text-xl': 'XL', 'text-2xl': 'XXL' },
          default: 'text-base',
        },
        link: {
          type: 'link',
          label: 'Link',
          enabled: false,
        },
        style: {
          type: 'select',
          label: 'Light / Dark',
          options: { light: 'Light', dark: 'Dark' },
          default: 'light',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div>
    <div
      class="flex title-paragraph relative justify-center"
    >
      <div
        class="py-8 p-8 md:p-12 container mx-auto grid gap-y-8 relative z-10 justify-center"
      >
        <div v-if="fields.illustration.enabled && fields.illustration.value !== 'none'" class="flex justify-center">
          <SanctuaryIllustration :drawing="fields.illustration.value" />
        </div>

        <div
          v-if="fields.title.enabled"
          class="flex flex-col justify-center text-center"
          :class="textColorClass"
        >
          <component
            :is="fields.titleTag.value || 'h1'"
            :class="fields.titleSize.value"
            class="font-serif font-light"
          >
            <VoixWysiwyg :field="fields.title" />
          </component>
        </div>
        <VoixWysiwyg
          v-if="fields.introduction.value"
          class="prose-p:mb-3 flex items-center justify-center text-center leading-[2em] text-gray-500"
          :class="[fields.introductionSize.value, textColorClass]"
          :field="fields.introduction"
        />
        <VoixLink :field="fields.link" class="text-light bg-sanctuary-primary text-sanctuary-secondary px-6 py-4 text-xs uppercase w-auto mx-auto" />
      </div>
    </div>
  </div>
</template>
