<script>
export default {
  mounted() {
    defineSlice({ name: { label: 'Show Covid    Info', group: 'global' }, fields: null, slots: [] })

    // @todo figure out the bus
    // $voix.bus.$emit('show-covid-19-link');
    // setTimeout(function(){
    // $voix.bus.$emit('show-covid-19-link');
    // }, 1000);
  },
}
</script>

<template>
  <div />
</template>
