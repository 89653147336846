<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: true,
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },
  mounted() {
    defineSlice({
      description: 'Hyatt Special Offer',
      name: {
        label: 'Special Offer',
        group: 'Hyatt',
      },
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true },
        subtitle: { type: 'text', label: 'Sub-Title' },
        content: { type: 'wysiwyg', label: 'Content' },
        terms: { type: 'wysiwyg', label: 'Terms & Conditions' },
        percentOff: { type: 'text', label: 'Percent Off' },
        extraSavings: { type: 'text', label: 'Extra Savings' },
        endDate: { type: 'date', label: 'End Date' },
        image: { type: 'media', label: 'Image' },
        hideBookNow: { type: 'checkbox', label: 'Hide Book Now', default: false },
        id: { type: 'text', label: 'ID' },
        htmlId: { type: 'text', label: 'Title' },
      },
      templates: [{
        label: 'Special Offer',
        fields: {
          title: { value: 'Special Offer' },
          content: { value: '<p>Book now and save up to 55% off your stay at Hyatt Ziva Cancun. Plus, kids stay free!</p>' },
          image: { value: 'https://playa-cms-assets.s3.amazonaws.com/styled/jewel_palm_beach/23/jewel-palm-beach-aerial-resort-02.jpg/16e582077497fce8cc991d0081196430' },
        },
      }],
    })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }"
    class="container mx-auto overflow-hidden"
  >
    <div class="w-full mb-6 pb-6 flex justify-stretch items-stretch">
      <div
        class="relative flex flex-col md:flex-row items-center min-w-full h-[650px]"
      >
        <div
          class="relative z-10 transition-all duration-[1.5s] ease-out"
          :class="{
            'md:-translate-x-64': !isVisible,
            'md:translate-x-0': isVisible,
          }"
          style="
                      filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
                  "
        >
          <div
            :id="fields?.htmlId?.value"
            class="px-12 md:pr-20 py-16 md:w-1/2 text-left bg-zilara-navy text-white"
            style="
                          clip-path: polygon(0 0, 100% 0, 66% 100%, 0% 100%);
                      "
          >
            <div class="w-5/6 md:w-full">
              <span
                class="block uppercase mb-1 text-sm"
                v-html="fields?.subtitle?.value"
              />
              <h3 class="text-xl md:text-3xl mb-4">
                {{ fields?.title?.value }}
              </h3>
              <div
                class="font-light"
                v-html="fields?.content?.value"
              />
            </div>

            <div
              v-if="fields?.percentOff?.value"
              class="w-5/6 md:w-full flex flex-col items-center mt-8"
            >
              <div class="flex uppercase mb-2">
                <div class="text-5xl font-bold">
                  Save
                </div>
                <div class="flex flex-col text-xs mx-1">
                  <div>Up</div>
                  <div>To</div>
                </div>
                <div class="text-5xl font-bold">
                  {{ fields?.percentOff?.value }}
                </div>
              </div>
              <div
                v-if="fields?.extraSavings"
                class="font-bold"
                v-html="fields?.extraSavings?.value"
              />
            </div>

            <div class="w-5/6 md:w-full flex mt-4">
              <PlayaBooking
                v-if="
                  currentPage
                    && currentPage.path !== '/agent-offers'
                    && currentPage.path !== '/es/agent-offers'
                    && (
                      !fields?.hideBookNow?.value ||
                      fields?.hideBookNow?.value === '0'
                    )
                "
              >
                <button
                  class="px-8 pt-4 pb-3 book-now rounded-full uppercase font-light bg-white text-zilara-navy"
                >
                  {{ $t('book-now') }}
                </button>
              </PlayaBooking>
            </div>

            <div class="w-5/6 md:w-full mt-12 font-light">
              <div v-if="fields?.endDate?.value">
                {{ $t('offer-expires') }}:
                {{ fields?.endDate?.value }}
              </div>
              <terms :terms="fields?.terms?.value">
                <button
                  class="uppercase underline underline-offset-2 decoration-1 font-light"
                >
                  {{ $t('terms-and-conditions') }}
                </button>
              </terms>
            </div>
          </div>
        </div>

        <div
          class="absolute inset-0 mt-24 -mb-24 md:my-12 transition-all duration-[1.5s] ease-out"
          :class="{
            'md:translate-x-64': !isVisible,
            'md:translate-x-0': isVisible,
          }"
        >
          <VoixMedia :field="fields?.image" class="object-cover w-full h-full" />
        </div>
      </div>
    </div>
  </div>
</template>
