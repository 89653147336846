<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    styles() {
      if (this.image) {
        return {
          backgroundImage: ` url(${this.image.url})`,
        }
      }
      return {}
    },
    image() {
      if ((this.$voix.breakpoint === 'mobile' || this.$voix.breakpoint === 'tablet') && this.fields.mobileBackgroundImage)
        return this.fields.mobileBackgroundImage

      return this.fields.backgroundImage
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Background Image Container', group: 'global' },
      preview: 'SlicesGlobalContainersBackgroundImageContainer.jpg',
      fields: { backgroundImage: { type: 'media', label: 'Background Image', breakpoints: { default: { width: 1200 } }, name: 'backgroundImage' }, mobileBackgroundImage: { type: 'media', label: 'Mobile Background Image', breakpoints: { default: { width: 1200 } }, name: 'mobileBackgroundImage' } },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="bg-cover" :style="styles">
    <slot />
  </div>
</template>
