<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage })
    },

  },

  mounted() {
    defineSlice({ name: { label: 'Single Press Release', group: 'playaresorts' }, slots: [] })
  },
}
</script>

<template>
  <div>
    <img v-if="post && post.hero" :src="post.hero" width="100%">

    <div v-if="post" class="flex justify-center">
      <div class="p-12 container items-center">
        <h1 class="pb-8 font-light">
          {{ post.title }}
        </h1>
        <div class="wysiwyg" v-html="post.content" />
        <div class="pt-10">
          <a href="/press-releases" class="btn" target="_top">Back to Press
            Room</a>
        </div>
      </div>
    </div>
  </div>
</template>
