defineSlice({"name":{"label":"Youtube","group":"global"},"fields":{"videoId":{"type":"text","label":"Youtube ID","name":"videoId"},"autoplay":{"type":"checkbox","label":"Autoplay?","default":true,"name":"autoplay"},"loop":{"type":"checkbox","label":"Loop?","default":true,"name":"loop"},"mute":{"type":"checkbox","label":"Mute?","default":true,"name":"mute"}},"slots":[]})

<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    embedUrl() {
      const autoplay = this.fields.autoplay.value ? '1' : '0'
      const loop = this.fields.loop.value ? '1' : '0'
      const mute = this.fields.mute.value ? '1' : '0'

      return `https://www.youtube.com/embed/${this.fields.videoId.value}?rel=0&autoplay=${autoplay}&loop=${loop}&mute=${mute}`
    },
  },
}
</script>

<template>
  <div class="flex justify-center w-full">
    <div class="py-14 container">
      <div class="yt-container">
        <iframe
          id="ytplayer" type="text/html"
          :src="embedUrl"
          frameborder="0"
          class="w-full aspect-video"
        />
      </div>
    </div>
  </div>
</template>
