<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    imageStyle() {
      const style = { }
      if (this.fields.maxWidth.value && this.$voix.breakpoint !== 'default')
        style['max-width'] = `${this.fields.maxWidth.value}px`

      if (this.fields.minWidth.value && this.$voix.breakpoint !== 'default')
        style['min-width'] = `${this.fields.minWidth.value}px`

      return style
    },
  },
  mounted() {
    defineSlice({ name: { label: 'Image', group: 'global' }, fields: { maxWidth: { type: 'text', label: 'Max Width', name: 'maxWidth' }, minWidth: { type: 'text', label: 'Min Width', name: 'minWidth' }, image: { type: 'media', label: 'Image', editorLabel: true, breakpoints: { default: { width: 1200, fit: 'contain' } }, name: 'image' } }, slots: [] })
  },
}
</script>

<template>
  <div class="text-center">
    <VoixMedia
      v-if="fields.image.value" class="w-full xl:w-auto mx-auto"
      :style="imageStyle"
      :field="fields.image"
    />
  </div>
</template>
