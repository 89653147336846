<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    dimValue() {
      if (this.fields.dim.value)
        return this.fields.dim.value

      return 0.5
    },
  },
  mounted() {
    defineSlice({
      fields: {
        backgroundImage: { type: 'media', label: 'Background (Parallax Image)', breakpoints: { default: { width: 1400, height: 1000 } } },
        title: { type: 'text', label: 'Title', editorLabel: true },
        description: { type: 'wysiwyg', label: 'Description' },
        dim: { type: 'text', label: 'Dim Amount' },
      },
      name: { label: 'Centered Text Parallax', group: 'Playa Collection' },
      templates: [{
        label: 'Centered Text Parallax',
        fields: {
          title: { value: 'Centered Text Parallax' },
          description: { value: '<p>Centered Text Parallax</p>' },
          backgroundImage: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hilton_Yucatan\/czmpd-rooftop-pool-01.jpg' },
          dim: { value: '0' },
        },
      }],
    })
  },
}
</script>

<template>
  <VoixMedia v-slot="slotProps" class="relative" :field="fields.backgroundImage" background>
    <div
      class="text-white h-full bg-cover bg-local lg:bg-fixed bg-center hidden md:block relative"
      :style="{ backgroundImage: `url('${slotProps.image}')` }"
    >
      <div
        v-if="dimValue > 0"
        class="absolute inset-0 z-10"
        :style="{ opacity: dimValue, backgroundColor: '#061f3e' }"
      />
      <div class="relative z-20">
        <div class="flex justify-center">
          <div class="container py-12 lg:py-16 text-center lg:px-16 min-h-[400px]">
            <h3 v-if="fields.title.value" class="mb-4 text-4xl font-sans-lato font-light">
              {{ fields.title.value }}
            </h3>
            <div v-if="fields.description.value" class="px-12" v-html="fields.description.value" />
          </div>
        </div>
      </div>
    </div>
  </VoixMedia>
</template>
