<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    colors() {
      return {
        color: this.fields.textColor.value,
      }
    },
    image() {
      if (this.fields.bgImage.value.length) {
        return {
          backgroundImage: ` url(${this.fields.bgImage.value[0].url})`,
        }
      }
      return {
        backgroundImage:
            ' url(https://playaresorts-app.nyc3.digitaloceanspaces.com/media/playacares/destinations/mexico-main-banner1.jpg)',
      }
    },
  },
  mounted() {
    defineSlice({ name: { label: 'Full Width Stats', group: 'playa-cares' }, fields: { title: { type: 'text', label: 'Title', default: 'Activities', name: 'title' }, col1Title: { type: 'text', label: 'Column 1 Title', default: '10+', name: 'col1Title' }, col1Text: { type: 'text', label: 'Column 1 Text', default: 'Social Community Events', name: 'col1Text' }, col2Title: { type: 'text', label: 'Column 2 Title', default: '350+', name: 'col2Title' }, col2Text: { type: 'text', label: 'Column 2 Text', default: 'Participant Associates', name: 'col2Text' }, col3Title: { type: 'text', label: 'Column 3 Title', default: '118 hrs', name: 'col3Title' }, col3Text: { type: 'text', label: 'Column 3 Text', default: 'Destined to Social Causes', name: 'col3Text' }, col4Title: { type: 'text', label: 'Column 4 Title', default: '24,310 USD', name: 'col4Title' }, col4Text: { type: 'text', label: 'Column 4 Text', default: 'In Donations for Different Causes', name: 'col4Text' }, textColor: { type: 'color', label: 'Text Color', default: null, name: 'textColor' }, bgImage: { type: 'media', label: 'Background Image', name: 'bgImage' } }, slots: [] })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div
      class="p-8 md:p-12 md:px-12 w-full text-center bg-playa-action bg-cover"
      :style="image"
    >
      <div class="text-white px-2 pb-8 font-light">
        <span class="border-b border-abs-white py-2 px-8 text-4xl">{{
          fields.title.value
        }}</span>
      </div>
      <div
        class="flex justify-center items-center flex-col md:flex-row mt-8"
      >
        <div
          class="flex justify-stretch items-stretch md:mb-0 md:w-1/4"
        >
          <div class="w-full">
            <div
              class="text-4xl title text mb-2 text-white italic"
              :style="colors"
            >
              {{ fields.col1Title.value }}
            </div>
            <div class="text-2xl mb-4 text-white">
              {{ fields.col1Text.value }}
            </div>
          </div>
        </div>
        <div
          class="flex justify-stretch items-stretch md:mb-0 md:w-1/4"
        >
          <div class="w-full">
            <div
              class="text-4xl title text mb-2 text-white italic"
              :style="colors"
            >
              {{ fields.col2Title.value }}
            </div>
            <div class="text-2xl mb-4 text-white">
              {{ fields.col2Text.value }}
            </div>
          </div>
        </div>
        <div
          class="flex justify-stretch items-stretch md:mb-0 md:w-1/4"
        >
          <div class="w-full">
            <div
              class="text-4xl title text mb-2 text-white italic"
              :style="colors"
            >
              {{ fields.col3Title.value }}
            </div>
            <div class="text-2xl mb-4 text-white">
              {{ fields.col3Text.value }}
            </div>
          </div>
        </div>
        <div
          class="flex justify-stretch items-stretch md:mb-0 md:w-1/4"
        >
          <div class="w-full">
            <div
              class="text-4xl title text mb-2 text-white italic"
              :style="colors"
            >
              {{ fields.col4Title.value }}
            </div>
            <div class="text-2xl mb-4 text-white">
              {{ fields.col4Text.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
