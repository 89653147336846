<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    slice: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
  },
  mounted() {
    defineSlice({ name: { label: 'General Slider', group: 'global' }, fields: { arrowColors: { type: 'color', label: 'Arrow Color', default: null, name: 'arrowColors' }, arrowSize: { type: 'number', label: 'Arrow Size', default: '50', name: 'arrowSize' }, center: { type: 'checkbox', label: 'Center', default: false, name: 'center' } }, slots: [{ name: 'default', allowedElements: [] }] })
  },
}
</script>

<template>
  <div class="w-full">
    <GeneralSwiper :looping="true" :navigation="true">
      <VoixSlice
        v-for="element in slice.elements"
        :key="element.id"
        :slice="element"
      />
    </GeneralSwiper>
  </div>
</template>
