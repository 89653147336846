
<template>
    <div>
        <div class="flex justify-center items-center flex-col md:flex-row items-stretch py-8 w-full">
            <div
                class="md:p-4 flex items-stretch flex justify-center md:justify-end items-stretch mx-4 mb-8 md:mb-0 md:w-1/4 border-0 md:border-r-2 border-grey-dark" style="border-color: #999999;">
                <a href="https://www.playaresorts.com/covid-1nine-updates">
                    <img
                        src="https://playaresorts-app.nyc3.digitaloceanspaces.com/media/safe-stay/bar/travel-requirements.png"
                        style="max-width:325px;max-height:75px" class="mr-6">
                </a>
            </div>
            <div class="md:p-4 flex items-stretch flex justify-center md:justify-start items-stretch mx-4 mb-8 md:mb-0 md:w-1/4">
                <a href="https://playaresorts.com/safe-stay-promise">
                <img src="https://playaresorts-app.nyc3.digitaloceanspaces.com/media/safe-stay/bar/safe-stay.png"
                     style="max-width:325px;max-height:75px">
                </a>
            </div>
        </div>
    </div>

</template>

<script>
import { usePageStore } from '@voix/store/pageStore'
export default {
  props: {
      fields: {
        type: Object,
        default: () => ({}),
      },
      slice: {
        type: Object,
        default: () => ({}),
      }
  },
  mounted () {
      defineSlice({"name":{"label":"Safe Stay Bar","group":"Global"},"templates":[{"label":"Safe Stay Bar","fields":[]}]})
  },
};
</script>