<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      name: { label: 'Content Grid', group: 'Playa Collection' },
      templates: [{ label: 'Content Grid', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
    <slot />
  </div>
</template>
