<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      windowWidth: 0,
    }
  },
  computed: {
    overrideStyles() {
      if (this.windowWidth < 950)
        return {}

      if (this.windowWidth < 1280) {
        const height = Math.round(this.windowWidth * 0.673)
        return {
          backgroundImage: 'url(https://playa-cms-assets.s3.amazonaws.com/media/weddings/single-floral-ring.jpeg)',
          height: `${height}px`,
        }
      }

      return {
        backgroundImage: 'url(https://playa-cms-assets.s3.amazonaws.com/media/weddings/double-floral-rings.jpeg)',
      }
    },
  },
  mounted() {
    defineSlice({
      description: 'Weddings Side Content Call To Action',
      preview: 'SlicesWeddingsBgSideContentCallToAction.jpg',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: {
            lg: {
              width: 800,
              height: 800,
            },
            default: {
              width: 600,
              height: 600,
            },
          },
        },
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
        },
        introduction: {
          type: 'wysiwyg',
          label: 'Introduction',
        },
        link: {
          type: 'link',
          label: 'Link',
          default: {
            text: 'Learn More',
          },
        },
      },
      name: {
        label: 'Weddings Side Content Call To Action',
        group: 'Weddings',
      },
      templates: [],
    })
    if (process.client) {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
      this.windowWidth = window.innerWidth
    }
  },
}
</script>

<template>
  <div
    class="mx-auto bg-cover text-center flex flex-col md:flex-row justify-between flex-grow items-center
        w-full
        xl:w-[1280px] xl:h-[888px]"
    :style="overrideStyles"
  >
    <div class="hidden xl:block w-1/2 overflow-visible">
      <VoixMedia :field="fields.image" class="rounded-full" style="max-width: none;width:582px;height:582px;margin-top:227px;margin-left:105px;" />
    </div>
    <div class="pt-0 md:pt-32 pl-4 pr-4 md:pl-[36%] md:pr-[30%] xl:pt-48 xl:pl-16 xl:pr-24 mx-auto xl:w-1/2">
      <div class="text-center font-cursive font-light mt-0 md:mt-8 text-4xl xl:text-5xl" v-html="fields.title.value" />

      <div class="text-center text-sm my-8 leading-loose" v-html="fields.introduction.value" />

      <a
        :href="fields.link?.value?.href"
        class="uppercase mx-auto rounded-full bg-hyatt-wedding-action px-6 py-2 text-sm text-abs-white"
      >
        {{ fields.link?.value?.text }}
      </a>
    </div>
  </div>
</template>
