<script>
import { mapActions } from 'pinia'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({ name: { label: 'Booking Exclude Resorts', group: 'global' }, fields: { exclusions: { type: 'textarea', label: 'Exclude resorts', instructions: 'Provide the list of the resorts by name separated by commas. Example: Hyatt Ziva Los Cabos, Hyatt Zilara Cancun (Age 18+).You MUST refresh after you make changes to see the effects.', name: 'exclusions' } }, slots: [] })

    this.setBookingExcludeResorts(this.fields.exclusions.value)
  },
  methods: {
    ...mapActions(useBookingWidgetStore, ['setBookingExcludeResorts']),
  },
}
</script>

<template>
  <div />
</template>
