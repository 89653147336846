<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Wyndham Inline Booking Widget',
      fields: {
        heightFromTop: { type: 'text', label: 'Height from Top', default: '"80"', instructions: '"The distance from the top of the screen as a percentage. 80 = 80% from top"' },
      },
      name: { label: 'Inline Booking Widget', group: 'Wyndham' },
      templates: [{ label: 'Inline Booking Widget', fields: [] }],
    })
  },
}
</script>

<template>
  <div class="container mx-auto hidden lg:block relative z-40">
    <inline-booking-form
      direction="top" class="lg:absolute lg:z-40 lg:top-0 lg:left-0 lg:right-0 lg:mb-16"
      :style="`margin-top:${fields.heightFromTop.value}vh`"
    />
  </div>
</template>
