<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      description: 'Big image with copy and link over it',
      fields: { image: { label: 'Image', type: 'media', breakpoints: { lg: { width: 1280, height: 800 }, sm: { width: 450, height: 1100 } } }, title: { label: 'Title', type: 'text' }, copy: { label: 'Copy', type: 'wysiwyg' }, link: { label: 'Link', type: 'link', enabled: false } },
      name: { label: 'Magazine Overlay', group: 'Hyatt' },
      templates: [{
        label: 'Magazine Overlay',
        fields: {
          copy: { value: '<p>The Riviera Maya is a stretch of Caribbean coastline on Mexico\u2019s northeastern Yucat\u00E1n Peninsula. It\'s known for its numerous all-inclusive resorts, such as those in the town of Playa del Carmen, and its long beaches. To the south, Tulum is home to yoga retreats and the preserved ruins of an ancient Mayan port city, perched on an outcrop above a white-sand beach.<\/p>' },
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/web\/Destination-Cancun.jpg' },
          title: { value: 'Riviera Maya' },
        },
      }],
    })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }" class="relative h-[700px]">
    <VoixMedia :field="fields.image" class="object-cover w-full h-full" />

    <div
      class="absolute inset-0 bg-gradient-to-t md:bg-gradient-to-br from-zilara-navy/90 to-zilara-navy/0"
    />

    <div
      class="absolute bottom-0 md:bottom-auto md:top-0 right-0 md:right-auto left-0 m-16 lg:w-2/5 text-white text-center md:text-left transition-opacity duration-[1.5s] delay-1000"
      :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
    >
      <h3 class="uppercase text-3xl font-light">
        {{ fields.title.value }}
      </h3>
      <div class="mt-4 font-light" v-html="fields.copy.value" />
      <a
        v-if="fields.link.enabled"
        :href="fields.link.value?.href"
        :target="fields.link.value.target"
        class="inline-block mt-8 px-8 pt-4 pb-3 rounded-full uppercase font-light bg-white text-zilara-navy"
      >
        {{ fields.link.value.text }}
      </a>
    </div>
  </div>
</template>
