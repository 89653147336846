<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    numberStyles() {
      return { color: this.fields.numberColor.value || 'white' }
    },
  },
  mounted() {
    defineSlice({ name: { label: 'Numbered Image Content Card', group: 'global' }, fields: { image: { type: 'media', label: 'Image', breakpoints: { default: { width: 600 } }, name: 'image' }, imageOverCopy: { type: 'media', label: 'Image Over Copy Right', breakpoints: { default: { width: 350, fit: 'contain' } }, name: 'imageOverCopy' }, numberColor: { type: 'text', label: 'Number Color', name: 'numberColor' }, number: { type: 'text', label: 'Number', name: 'number' }, ordinal: { type: 'text', label: 'Ordinal', name: 'ordinal' }, copy: { type: 'wysiwyg', label: 'Copy', enabled: false, name: 'copy' }, link: { type: 'link', label: 'link', enabled: false, name: 'link' } }, slots: [] })
  },
}
</script>

<template>
  <div>
    <div
      class="flex justify-center w-full"
    >
      <div class="p-0 md:p-10 container overflow-hidden relative">
        <div
          class="flex flex-col md:flex-row items-stretch md:-mx-8"
        >
          <div class="flex flex-justify md:w-1/2 p-4 text-center">
            <div
              class="relative overflow-hidden w-full aspect-[16/9]"
            >
              <VoixMedia
                v-if="fields.image.media && fields.image.media.length > 0"
                :field="fields.image"
                class="absolute absolute-center w-[120%] max-w-[120%] h-auto"
              />
            </div>
          </div>
          <div class="md:w-1/2 p-4 pb-0 text-center md:text-left">
            <div class="flex flex-col lg:flex-row justify-between">
              <div class="text-white text-3xl max-w-[170px] mx-auto lg:mx-0">
                <div class="flex font-serif-times font-[200] mb-1" :style="numberStyles">
                  <div class="w-1/2 text-8xl -mr-2" style="line-height: .75;font-weight:100;">
                    {{ fields.number.value || '1' }}
                  </div>
                  <div class="text-4xl w-1/2 -mt-2 -ml-2">
                    <div>
                      {{ fields.ordinal.value || 'st' }}&nbsp;Day
                    </div>
                    <div>Prize</div>
                  </div>
                </div>
                <div class="text-3xl font-sans-serif uppercase">
                  <div class="font-bold rubik" style="line-height: .9;">
                    3-Night
                  </div>
                  <div class="font-bold rubik" style="line-height: .9;">
                    Stay
                  </div>
                  <div class="font-light rubik" style="line-height: .9;">
                    For Two
                  </div>
                </div>
              </div>
              <div class="w-full lg:w-3/5 my-2 lg:my-0">
                <VoixMedia
                  v-if="fields.imageOverCopy.media && fields.imageOverCopy.media.length > 0"
                  class="mx-auto md:mx-0 w-full mb-4 my-4 md:my-0"
                  :field="fields.imageOverCopy"
                />
              </div>
            </div>
            <div
              v-if="fields.copy.enabled"
              class="my-4 wysiwyg text-xs text-white"
              v-html="fields.copy.value"
            />
            <a
              v-if="fields.link.enabled"
              :href="fields.link.value.href"
              class="btn inline-block my-2"
            >
              {{ fields.link.value.text }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
