<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      fields: {
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 800 } } },
        title: { type: 'text', label: 'Title', editorLabel: true },
        description: { type: 'wysiwyg', label: 'Description' },
        onRight: { type: 'checkbox', label: 'Display on Right Side' },
      },
      name: { label: 'Block Over Image', group: 'Playa Collection' },
      templates: [{
        label: 'Block Over Image',
        fields: {
          description: { value: '<p><\/p><p>1. <strong>DESIGNED TO GIVE TRAVELERS CHOICES!<\/strong><\/p><p>With the ultimate innovation of travel, we offer services full of extraordinary benefits and substantial savings.<\/p><p>2. <strong>TRAVELING TAILORED TO YOU!<\/strong><\/p><p>Travel whenever you want and with whoever&nbsp; you want, without restrictions!&nbsp; <br>Plus add some of the best travel accommodations and high-class transportation to your vacation.<\/p><p>3. <strong>INNOVATION THROUGH MEMBERSHIP! <\/strong><\/p><p>You can enjoy countless benefits, including; visiting local attractions,<br>riding in luxury transportation, and restaurant reservations that will make your vacation feel unique!<\/p>' },
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/playacollection\/tpc-background.jpg' },
          onRight: { value: true },
          title: { value: 'Welcome to The Playa Collection\u2122, a world full of benefits created just for you' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="pt-12">
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div
        class="w-full bg-cover bg-center pb-12"
        :style="{ backgroundImage: `url('${slotProps.image}')` }"
      >
        <div class="flex justify-center">
          <div class="container flex" :class="{ 'justify-start': !fields.onRight.value, 'justify-end': fields.onRight.value }">
            <div class=" w-full lg:w-4/5 bg-white p-8 lg:p-12 mt-12 mx-4 lg:mx-0 lg:-mt-12">
              <h3 class="text-xl xl:text-3xl font-sans-lato font-bold mb-4">
                {{ fields.title.value }}
              </h3>
              <div v-html="fields.description.value" />
            </div>
          </div>
        </div>
      </div>
    </VoixMedia>
  </div>
</template>
