<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage, slice: this.slice })
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlobalBookingInlineFlightBookingWidget.jpg',
      description: 'Inline Flight Booking Widget',
      fields: { showDebug: { label: 'Show Debug', type: 'checkbox' } },
      name: { label: 'Inline Flight Booking Widget', group: 'Global' },
      templates: [{ label: 'Inline Flight Booking Widget', fields: { showDebug: { value: true } } }],
    })
  },
}
</script>

<template>
  <div class="relative z-40 flex justify-center inline-flight-booking">
    <flight-booking-form :devise="devise" :debug="fields.showDebug.value" class="p-4" />
  </div>
</template>
