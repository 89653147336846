<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Hero Image', group: 'global' },
      preview: 'SlicesGlobalHeroesHeroImage.jpg',
      fields: {
        image: {
          type: 'media',
          label: 'Image',
          editorLabel: true,
          breakpoints: { default: { width: 1200 } },
          name: 'image',
        },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div class="w-full">
    <VoixMedia
      :field="fields.image"
      class="w-full"
    />
  </div>
</template>
