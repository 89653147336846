<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },

  mounted() {
    defineSlice({ name: { label: 'We Want Your Input', group: 'playaresorts' }, fields: { link: { type: 'link', label: 'Link', name: 'link' }, text: { type: 'wysiwyg', label: 'Text', default: 'Playa Shadow BoardShare Your Ideas with CEO Bruce Wardinsky&nbsp;At Playa Hotels & Resorts, we take our values seriously - which is why we', name: 'text' } }, slots: [] })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div class="text-center flex flex-col md:flex-row" style="background-color:#1f1e1a;">
      <div class="md:w-1/2 pr-4 h-64 md:h-auto overflow-hidden">
        <img class="slither-lazy w-full" src="/imgs/we-want-input/bruce.w.jpeg">
      </div>
      <div class="p-8 md:p-0 md:w-1/2 text-left text-white">
        <h2 class="mt-0 md:mt-24 font-light text-5xl mb-4">
          We Want
          <span class="italic relative">
            Your
            <span
              style="background-image: url('/imgs/blue-swhoosh.jpeg');bottom:-40px;"
              class="italic absolute-center-x absolute w-32 h-12 bg-no-repeat"
            >&nbsp;</span>
          </span>
          Input
        </h2>
        <div class="mt-12" v-html="fields.text.value" />
        <p class="mt-8">
          <a v-if="fields.link.value" :target="fields.link.value.target" :href="fields.link.value.href" class="btn">{{ fields.link.value.text }}</a>
        </p>
      </div>
    </div>
  </div>
</template>
