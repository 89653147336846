<script>
import Cookies from 'js-cookie'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    targetName() {
      return this.fields.targetName.text
    },
  },
  mounted() {
    defineSlice({
      description: 'Agent portal popup',
      fields: { mode: { type: 'select', label: 'Mode', options: { 'automatic': 'Automatic (provide cookie name)', 'on-click': 'On Click (provide target name)', 'always-show': 'Always Show (for debugging most likely)' } }, cookieName: { type: 'text', label: 'Cookie Name' }, targetName: { type: 'text', label: 'Target Name', instructions: '"ex: \\"#target-name\\". Needs to be all lowercase and hyphenated."' }, image: { type: 'media', label: 'Image', breakpoints: { default: { width: 500, height: 600 } } }, title: { type: 'text', label: 'Title' }, copy: { type: 'wysiwyg', label: 'Copy' }, link: { type: 'link', label: 'Link', enabled: false }, appendLogoOnTitle: { type: 'select', label: 'Append Logo on Title', options: { agentCashPlus: 'Agent Cash +' }, enabled: false } },
      name: { label: 'Popup', group: 'Agents Portal' },
      templates: [{
        label: 'Popup',
        fields: {
          appendLogoOnTitle: { value: 'agentCashPlus' },
          copy: { value: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mollis quam at purus semper ullamcorper. Mauris mollis lorem sit amet congue faucibus. Duis nibh eros, dapibus in felis non, varius venenatis quam. Morbi congue lacinia elit in ullamcorper. Ut placerat diam arcu, vel ultricies turpis vehicula sed. Ut eleifend enim et sem varius ultricies. Nunc hendrerit, ex vitae ullamcorper viverra, quam purus imperdiet metus, eget pretium urna elit non orci.<\/p>' },
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/01-hyatt-zilara-hyatt-ziva-cap-cana-beach-weddings-couple-15.jpg' },
          link: { value: { text: 'Learn More', target: '_self', relationship: null, href: '\/new-page' } },
          mode: { value: 'on-click' },
          targetName: { value: '#show-my-popup' },
          title: { value: 'Welcome To' },
        },
      }],
    })

    if (this.fields.mode.value === 'always-show')
      this.show = true

    if (process.client && window && this.fields.mode.value === 'on-click') {
      window.onhashchange = () => {
        if (window.location.hash === this.targetName)
          this.show = true
      }
    }

    if (this.fields.mode.value === 'automatic') {
      if (!Cookies.get(this.fields.cookieName.text)) {
        this.show = true
        Cookies.set(this.fields.cookieName.text, true)
      }
    }
  },
  methods: {
    close() {
      if (window && this.fields.mode.value === 'on-click') {
        history.pushState(
          '',
          document.title,
          window.location.pathname + window.location.search,
        )
      }

      this.show = false
    },
  },
}
</script>

<template>
  <transition name="dvs-fade">
    <div
      v-if="show"
      class="fixed z-50 inset-0 flex justify-center items-center"
    >
      <div
        class="absolute z-40 inset-0 bg-black/50 cursor-pointer"
        @click="close"
      />
      <div
        class="relative z-50 w-3/4 max-w-3xl bg-white flex items-stretch shadow-2xl"
      >
        <button class="absolute top-0 right-0 m-4" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-gray-700"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div class="w-1/3">
          <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
        </div>
        <div class="w-2/3 p-8">
          <div class="flex items-center text-2xl text-black">
            <span v-html="fields.title.value" />
            <LogosAgentCashPlus
              v-if="
                fields.appendLogoOnTitle.enabled
                  && fields.appendLogoOnTitle.value
                    === 'agentCashPlus'
              "
              class="h-14"
            />
          </div>
          <div class="text-sm" v-html="fields.copy.value" />
          <button
            v-if="fields.link.enabled"
            :href="fields.link?.value?.href"
            class="bg-[#0e477c] text-white py-3 px-5 uppercase font-bold text-xs"
          >
            {{ fields.link?.value?.text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
