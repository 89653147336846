<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Contact information row intended for the table slice',
      fields: { name: { type: 'text', label: 'Name', editorLabel: true }, phone: { type: 'text', label: 'Phone Number', enabled: false }, email: { type: 'text', label: 'Email', enabled: false } },
      name: { label: 'Contact Information Row', group: 'Global' },
      templates: [{
        label: 'Contact Information Row',
        fields: {
          name: { enabled: true, value: 'US & CANADA RESERVATIONS' },
          phone: { enabled: true, value: '833.297.5292' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="table-row w-full">
    <div class="table-cell py-4 px-8">
      {{ fields.name.value }}
    </div>
    <div class="table-cell py-4 px-8">
      <a v-if="fields.phone.enabled" :href="`tel:${fields.phone.value}`">
        {{ fields.phone.value }}
      </a>
    </div>
    <div class="table-cell py-4 px-8">
      <a v-if="fields.email.enabled" :href="`mailto:${fields.email.value}`">
        {{ fields.email.value }}
      </a>
    </div>
  </div>
</template>
