<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      description: 'Gallery with pointy title and copy',
      fields: {
        image1: { label: 'Image 1', type: 'media', breakpoints: { lg: { width: 1265, height: 600 }, sm: { width: 450, height: 330 } } },
        image2: { label: 'Image 2', type: 'media', breakpoints: { lg: { width: 617, height: 384 }, sm: { width: 400, height: 250 } } },
        image3: { label: 'Image 3', type: 'media', breakpoints: { lg: { width: 1265, height: 600 }, sm: { width: 450, height: 330 } } },
        title: { label: 'Title', type: 'text' },
        copy: { label: 'Copy', type: 'wysiwyg' },
        link: { label: 'Link', type: 'link', enabled: false },
      },
      name: { label: 'Fractured Gallery', group: 'Hyatt' },
      templates: [{
        label: 'Fractured Gallery',
        fields: {
          copy: { value: '<p>Whether you want to do it all or nothing at all, Hyatt Zilara Riviera Maya presents the option to move back and forth between recreation and rest. Pack your day with everything from water sports to wine tastings or lay low pool- or beach-side. When the day is done, nightly live entertainment fills the air with excitement.<\/p>' },
          image1: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-yoga-class-2.jpg' },
          image2: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-bike-tour-1.jpg' },
          image3: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-zen-spa-chaman-experience-2.jpg' },
          link: { enabled: true, value: { text: 'EXPLORE THINGS TO DO', target: '_self', relationship: null, href: '\/new-page' } },
          title: { value: 'Things to Do' },
        },
      }],
    })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }" class="container mx-auto relative">
    <div
      class="md:h-[600px] w-full transition-opacity duration-[1.5s] delay-1000 ease-out"
      :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
    >
      <VoixMedia :field="fields.image1" class="object-cover w-full h-full" />
    </div>
    <div
      class="hidden md:block md:h-[600px] w-full transition-opacity duration-[1.5s] delay-[3s] ease-out"
      :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
    >
      <VoixMedia :field="fields.image3" class="object-cover w-full h-full" />
    </div>

    <div
      class="absolute z-10 top-0 md:inset-0 mt-[120px] mx-12 md:mt-0 hidden md:flex justify-center items-center"
    >
      <div
        class="md:h-[400px] md:w-1/2 ml-16 shadow-2xl transition-opacity duration-[1.5s] delay-[1.5s] ease-out"
        :class="{
          'opacity-0': !isVisible,
          'opacity-100': isVisible,
        }"
      >
        <VoixMedia :field="fields.image2" class="object-cover w-full h-full" />
      </div>
    </div>

    <div
      class="lg:absolute lg:z-20 lg:inset-0 flex items-end lg:items-center"
    >
      <div
        class="lg:w-2/5 lg:pt-80"
        style="filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.2))"
      >
        <div
          class="relative z-10 bg-zilara-navy text-white mx-6 md:mx-0 -mt-12 md:mt-0 p-10 pb-20 md:p-16 md:pb-24"
          style="clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%)"
        >
          <h3 class="uppercase text-3xl font-light">
            {{ fields.title.value }}
          </h3>
          <div
            class="mt-4 font-light"
            v-html="fields.copy.value"
          />
          <a
            v-if="fields.link.enabled"
            :href="fields.link.value?.href"
            :target="fields.link.value.target"
            class="inline-block mt-8 px-8 pt-4 pb-3 rounded-full uppercase font-light bg-white text-zilara-navy"
          >
            {{ fields.link.value.text }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
