<script>
import { SwiperSlide } from 'swiper/vue'

export default {

  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      name: { label: 'Left Right Curve Block', group: 'Hyatt' },
      slots: [{ name: 'default' }],
      description: 'Full width background with a masked swoosh image flanking title, sub-title, description and slices.',
      fields: { textarea: { type: 'textarea', label: 'Text Area' }, title: { type: 'text', label: 'Title', editorLabel: true }, subtitle: { type: 'text', label: 'Sub-Title' }, description: { type: 'wysiwyg', label: 'Description' }, imagesOnLeft: { type: 'checkbox', label: 'Images on Left?', instructions: '"Images on right by default"', default: false }, color: { type: 'color', label: 'Background Color', default: '#201C57' }, fgcolor: { type: 'color', label: 'Text Color', default: '#ffffff' }, image1: { type: 'media', label: 'Image', breakpoints: { lg: { width: 1201, height: 800 }, md: { width: 900, height: 600 }, sm: { width: 600, height: 400 } } } },
      templates: [{ label: 'Left Right Curve Block', fields: { description: { value: 'Six dining venues invite you to sample regional favorites, an array of international dishes, and delicious libations from mixologists and sommeliers. Savor fresh seafood beachside or enjoy a teppanyaki show. From sumptuous buffets to tapas and fine tequila, it\u2019s all included.' }, image1: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/styled\/www.resortsbyhyatt.com\/Hyatt_Zilara_Cancun\/Hyatt_Zilara_Cancun_Dining\/Hyatt-Zilara-Cancun-Cantina-La-Adelita-Appetizers3-3cda2e9a37173c74dd085eb3b8d46301.jpg' }, title: { value: 'Dining & Drinks' } } }],
    })
  },
}
</script>

<template>
  <div class="container relative overflow-hidden z-10 mb-1 mt-8" :style="{ backgroundColor: fields.color.value }">
    <div
      class="-mx-12 flex flex-col-reverse md:flex-row items-stretch justify-between"
      :class="{ 'md:flex-row-reverse': fields.imagesOnLeft.value === true }"
    >
      <div class="text-left md:w-1/3 p-4 md:py-16 relative z-10 px-12">
        <div class="relative z-10 px-2" :style="{ color: fields.fgcolor.value }">
          <div class="text-3xl uppercase title font-light mb-1 dvs-uppercase" :style="{ color: fields.fgcolor.value }">
            {{ fields.title.value }}
          </div>
          <div class="font-thin mb-4">
            {{ fields.subtitle.value }}
          </div>
          <div class="wysiwyg leading-loose" v-html="fields.description.value" />
          <div class="mt-8 text-center">
            <slot />
          </div>
        </div>
      </div>
      <div class="w-full md:w-2/3">
        <div class="h-full w-full relative z-10 bg-curve-block bg-cover" :style="{ backgroundImage: `url(${fields.image1.value?.[0]?.url})` }">
          <background-elements-swoosh-half-right v-if="$voix.breakpoint !== 'default' && fields.imagesOnLeft.value === false" :flip="true" class="hidden absolute z-0 left-0 -ml-1  pointer-events-none" :color="fields.color.value" w="50%" h="100%" />
          <background-elements-swoosh-half-right v-if="$voix.breakpoint !== 'default' && fields.imagesOnLeft.value === true" class="hidden absolute z-0 right-0 -mr-1  pointer-events-none" :color="fields.color.value" w="50%" h="100%" />
        </div>
      </div>
    </div>
  </div>
</template>
