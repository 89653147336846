<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    imageHeight() {
      if (this.$voix.breakpoint === 'default' || this.$voix.breakpoint === 'sm')
        return 'height:200px'

      return 'height:470px'
    },

  },
  mounted() {
    defineSlice({
      description: 'Specials card styled horizontally',
      name: { label: 'Special Offer Block', group: 'Playaresorts' },
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true },
        subtitle: { type: 'text', label: 'Sub-Title' },
        content: { type: 'wysiwyg', label: 'Content' },
        terms: { type: 'wysiwyg', label: 'Terms & Conditions' },
        percentOff: { type: 'text', label: 'Percent Off' },
        extraSavings: { type: 'text', label: 'Extra Savings' },
        endDate: { type: 'date', label: 'End Date' },
        image: {
          type: 'media',
          label: 'Image',
          breakpoints: { default: { width: 400, height: 300 } },
        },
        hideBookNow: { type: 'checkbox', label: 'Hide Book Now', default: false },
        imagesOnLeft: { type: 'checkbox', label: 'Images on Left?', instructions: '"Images on right by default"', default: false },
        bgsvg: { type: 'select', label: 'Content Background Graphic', options: { 'scribble-circle': 'Scribble Circle', 'spider-web': 'Spider Web', 'spiral-coral': 'Spiral Coral', 'spiral-coral-web': 'Spiral Coral Web' } },
        id: { type: 'text', label: 'ID' },
        htmlId: { type: 'text', label: 'Title' },
      },
      templates: [{
        label: 'Special Offer Block',
        fields: {
          title: { value: 'Special Offer' },
          content: { value: '<p>Book now and save up to 55% off your stay at Hyatt Ziva Cancun. Plus, kids stay free!</p>' },
          image: { value: 'https://playa-cms-assets.s3.amazonaws.com/styled/jewel_palm_beach/23/jewel-palm-beach-aerial-resort-02.jpg/16e582077497fce8cc991d0081196430' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="w-full mb-6 flex justify-stretch items-stretch">
    <div class="flex items-stretch flex-col md:flex-row items-center w-full special-offer-card-block">
      <VoixMedia v-slot="slotProps" :field="fields.image" background>
        <div

          :style="{ backgroundImage: `url(${slotProps.image})` }"
          class="inset-outline w-full md:w-3/5 bg-cover bg-center"
        />
      </VoixMedia>
      <div :id="fields.htmlId.value" class="px-8 pb-8 md:w-2/5 md:text-left">
        <div class="my-8 md:2-1/2">
          <span class="block uppercase mb-1 text-sm">{{ fields.title.value }}</span>
          <h3 class="text-3xl mb-4" v-html="fields.subtitle.value" />
          <div v-html="fields.content.value" />
        </div>

        <div v-if="fields.percentOff.value" class="flex flex-col items-center mb-8">
          <div class="flex items-center uppercase mb-2">
            <div class="text-5xl font-bold">
              Save
            </div>
            <div class="flex flex-col text-xs mx-1">
              <div>Up</div>
              <div>To</div>
            </div>
            <div class="text-5xl font-bold">
              {{ fields.percentOff.value }}
            </div>
          </div>
          <div v-if="fields.extraSavings.value" class="font-bold" v-html="fields.extraSavings.value" />
        </div>

        <div class="flex justify-center md:justify-start">
          <PlayaBooking v-if="currentPage && currentPage.path !== '/agent-offers' && currentPage.path !== '/es/agent-offers' && (!fields.hideBookNow.value || fields.hideBookNow.value === '0')">
            <button class="btn my-4 book-now">
              Book Now
            </button>
          </PlayaBooking>
        </div>

        <terms :terms="fields.terms.value">
          <button class="text-wyndham-action mb-2">
            Terms & Conditions
          </button>
        </terms>
        <div v-if="fields.endDate.value">
          Offer Expires: {{ fields.endDate.value }}
        </div>
      </div>
    </div>
  </div>
</template>
