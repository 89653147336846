<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Infinity Luxury Hero',
      fields: {
        bigImage: { type: 'media', label: 'Large Hero Image', enabled: false, breakpoints: { lg: { width: 1440, height: 800 }, sm: { width: 350, height: 400 } } },
        image: { type: 'media', label: 'Short Hero Image', breakpoints: { lg: { width: 1440, height: 350 }, sm: { width: 350, height: 200 } } },
        showLogo: { type: 'checkbox', label: 'Show Logo' },
      },
      name: { label: 'Infinity Hero', group: 'Infinity Luxury' },
      templates: [{
        label: 'Infinity Hero',
        fields: {
          bigImage: {
            value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/seadust-cancun-beach-2.jpg',
          },
          image: {
            value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/seadust\/seadust-cancun-beach-2.jpg',
          },
          showLogo: { value: true },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center relative z-0 ">
    <div class="relative max-w-[1440px] w-full">
      <div class="absolute inset-0 bg-gradient-to-b from-black/75 to-black/0 " />
      <VoixMedia v-if="fields.bigImage.enabled" :field="fields.bigImage" class="w-full" />
      <VoixMedia v-else :field="fields.image" class="w-full" />

      <div v-if="fields.showLogo.value" class="absolute z-20 top-0 left-0 right-0 py-48 flex justify-center">
        <img src="/imgs/infinity-luxury/logo.png" alt="Infinity Luxury Travel Club Logo" class="h-48">
      </div>
    </div>
  </div>
</template>
