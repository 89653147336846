<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  components: {
    RoomTour: () => import('@/components/RoomTour'),
  },
  props: {
    slice: {
      type: Object,
      required: true,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    overrideStyles() {
      if (this.fields.includedBackground) {
        return {
          backgroundColor: this.fields.includedBackground.color,
          color: this.fields.includedForeground.color,
        }
      }
      return {}
    },
    hasAnImage() {
      if (
        this.fields.topImage.url
          || this.fields.topImage2.enabled
          || this.fields.topImage3.enabled
          || this.fields.topImage4.enabled
          || this.fields.topImage5.enabled
      )
        return true

      return false
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        { fields: this.fields, page: this.currentPage },
        this.$voix,
      )
    },
  },

  mounted() {
    defineSlice({ name: { label: 'Vertical Image Card', group: 'playaresorts' }, fields: { tourUrl: { type: 'text', label: 'Tour URL', enabled: false, name: 'tourUrl' }, topImage: { type: 'media', label: 'Top Image 1', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 300 } }, name: 'topImage' }, topImage2: { type: 'media', label: 'Top Image 2', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage2' }, topImage3: { type: 'media', label: 'Top Image 3', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage3' }, topImage4: { type: 'media', label: 'Top Image 4', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage4' }, topImage5: { type: 'media', label: 'Top Image 5', breakpoints: { lg: { width: 561, height: 300 }, sm: { width: 300, height: 150 } }, enabled: false, name: 'topImage5' }, included: { type: 'text', label: 'Included Banner Text', default: 'Included', enabled: false, name: 'included' }, includedBackground: { type: 'color', label: 'Included Banner BG Color', name: 'includedBackground' }, includedForeground: { type: 'color', label: 'Included Banner FG Color', name: 'includedForeground' }, copyImage: { type: 'media', label: 'Image in Copy', enabled: false, breakpoints: { default: { width: 800 } }, name: 'copyImage' }, title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' }, copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' }, button: { type: 'link', label: 'Button Link', enabled: false, name: 'button' } }, slots: [{ name: 'default', allowedElements: [] }] })
  },
}
</script>

<template>
  <div class="flex justify-stretch bg-white shadow text-center">
    <div class="rounded relative w-full">
      <div class="flex flex-col overflow-hidden">
        <div class="pb-8">
          <div class="relative">
            <RoomTour
              v-if="fields.tourUrl.enabled"
              class="absolute left-0 bottom-0 bg-zilara-cancun-grey-dark p-4 pb-2 m-4 rounded-lg z-50"
              :tour-url="fields.tourUrl.value"
            >
              <VoixMedia
                width="50"
                height="26"
                media="/imgs/360-icon.png"
              />
            </RoomTour>
            <div
              v-if="fields.topImage.value && fields.topImage.value.length"
              class="h-[300px] w-full overflow-hidden aspect-video"
            >
              <VoixMedia
                v-if="fields.topImage.value"
                :field="fields.topImage"
                class="w-full h-full object-cover"
              />
            </div>
            <div v-if="fields.included.enabled" class="included absolute">
              <div
                class="flex justify-center items-center uppercase text-sm text-abs-white bg-currentsite-background"
                :style="overrideStyles"
              >
                <div class="font-bold">
                  {{ fields.included.value }}
                </div>
              </div>
            </div>
          </div>

          <div class="p-8 pb-12">
            <VoixMedia
              v-if="fields.copyImage.enabled"
              :field="fields.copyImage"
              :alt="fields.copyImage.alt"
              class="w-3/4 mb-4 mx-auto"
            />
            <h2 v-if="fields.title.value" class="mb-2 uppercase text-2xl">
              {{ fields.title.value }}
            </h2>
            <div class="wysiwyg mb-4" v-html="fields.copy.value" />

            <VoixSliceLoop v-for="e in slice.elements" :key="e.id" :element="e" />
          </div>
        </div>
        <a
          v-if="fields.button.enabled"
          :href="fields.button.value.href"
          :target="fields.button.value.target"
          class="btn btn-secondary bg-playa-action text-abs-white absolute bottom-0 left-0 right-0 mx-0 rounded-b"
        >
          {{ fields.button.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
