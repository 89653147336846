<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsFullWidthImage.jpg',
      fields: {
        image: {
          type: 'media',
          label: 'Background Image',
          breakpoints: {
            lg: { width: 1800, height: 800 },
            default: { width: 600, height: 600 },
          },
        },
      },
      name: { label: 'Weddings Full Width Image', group: 'Weddings' },
      templates: [{
        label: 'Weddings Full Width Image',
        fields: {
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Agents_Portal\/bannerv2.jpg' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="wide-image-bg-small-white-box">
    <VoixMedia v-slot="slotProps" :field="fields.image" background>
      <div
        class="hidden md:flex justify-center bg-grey bg-cover bg-center min-h-[600px]"
        :style="{ backgroundImage: `url(${slotProps.image})` }"
      />
    </VoixMedia>
  </div>
</template>
