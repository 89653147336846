<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ description: 'RM Cart Button Code', name: { label: 'Rm Cart Button Code', group: 'Global' }, templates: [{ label: 'Rm Cart Button Code', fields: [] }] })
    const rmscript = document.createElement('script')
    rmscript.setAttribute('type', 'text/javascript')
    rmscript.setAttribute('src', 'https://playaresorts.app.resmarksystems.com/javascript/resmark-order.js?entityId=60df865bedcc6100198c59cc&useBrandColors=true')
    this.$refs.scripttarget.appendChild(rmscript)
  },
}
</script>

<template>
  <div ref="scripttarget" />
</template>
