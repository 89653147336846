<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ description: 'Hides the Wyndham Sign up to save in footer', name: { label: 'Hide Sign Up To Save', group: 'Wyndham' }, templates: [{ label: 'Hide Sign Up To Save', fields: [] }] })
    setTimeout(() => {
      window.bus.$emit('wyndham-sign-up-to-save-hide')
    }, 500)

    setTimeout(() => {
      window.bus.$emit('wyndham-sign-up-to-save-hide')
    }, 1000)

    setTimeout(() => {
      window.bus.$emit('wyndham-sign-up-to-save-hide')
    }, 2000)
  },
}
</script>

<template>
  <div />
</template>
