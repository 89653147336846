<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({
      description: 'Hyatt two image description overlay',
      fields: { image1: { label: 'Image 1', type: 'media', breakpoints: { lg: { width: 950, height: 600 }, sm: { width: 450, height: 550 } } }, image2: { label: 'Image 2', type: 'media', breakpoints: { lg: { width: 950, height: 600 }, sm: { width: 450, height: 550 } } }, title: { label: 'Title', type: 'text' }, copy: { label: 'Copy', type: 'wysiwyg' }, link: { label: 'Link', type: 'link', enabled: false } },
      name: { label: 'Two Image Description Overlay', group: 'Hyatt' },
      templates: [{ label: 'Two Image Description Overlay', fields: { copy: { value: '<p>When the sun sets, rest and relax in stylish and spacious suites overlooking the sparkling sea and sand. Select from 291 rooms and suites in 12 different categories, each boasting luxe spa-like bathrooms with rainfall showers and fully furnished balconies to soak in the view.<\/p>' }, image1: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-room-tropical-view-king-1.jpg' }, image2: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-oceanfront-private-pool-king.jpg' }, link: { value: { text: 'EXPLORE ALL SUITES', target: '_self', relationship: null, href: '\/new-page' } }, title: { value: 'Sophisticated Suites' } } }],
    })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }"
    class="relative container mx-auto overflow-hidden flex flex-col md:flex-row justify-center"
  >
    <div class="hidden md:block absolute z-0 left-0 right-0 h-[600px]">
      <div
        class="absolute top-0 left-0 bottom-0 w-3/4 transition-all duration-[1.5s] delay-1000 ease-out"
        :class="{
          '-translate-x-64 opacity-0': !isVisible,
          'translate-x-0 opacity-100': isVisible,
        }"
        style="filter: drop-shadow(-1px -6px 6px rgba(50, 50, 0, 0.2))"
      >
        <VoixMedia :field="fields.image1" class="object-cover w-full h-full" />
      </div>
      <div
        class="absolute right-0 bottom-0 w-1/2 h-[500px] transition-all duration-[1.5s] delay-1000 ease-out"
        :class="{
          'translate-x-64 opacity-0': !isVisible,
          'translate-x-0 opacity-100': isVisible,
        }"
        style="filter: drop-shadow(-1px -6px 3px rgba(50, 50, 0, 0.2))"
      >
        <VoixMedia :field="fields.image2" class="object-cover w-full h-full" />
      </div>
    </div>

    <div
      class="md:hidden w-full transition-all duration-[1.5s] delay-1000 ease-out"
      :class="{
        'opacity-0': !isVisible,
        'opacity-100': isVisible,
      }"
      style="filter: drop-shadow(-1px -6px 6px rgba(50, 50, 0, 0.2))"
    >
      <VoixMedia :field="fields.image1" class="object-cover w-full h-full" />
    </div>

    <div
      class="-mt-16 md:mt-32 xl:mt-48 mx-8 md:mx-0 xl:w-3/4 md:pt-80"
      style="filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.2))"
    >
      <div
        class="relative z-10 bg-zilara-navy text-white py-12 md:py-16 px-4 md:px-16 md:pb-28 pb-20 text-center md:text-left"
        style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 85%)"
      >
        <h3 class="uppercase text-3xl font-light">
          {{ fields.title.value }}
        </h3>
        <div class="mt-4 font-light" v-html="fields.copy.value" />
        <a
          v-if="fields.link.enabled"
          :href="fields.link.value?.href"
          :target="fields.link.value.target"
          class="inline-block mt-8 px-8 pt-4 pb-3 rounded-full uppercase font-light bg-white text-zilara-navy"
        >
          {{ fields.link.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
