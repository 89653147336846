<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    whitewashStyles() {
      if (this.fields.whitewash.value) {
        return {
          backgroundColor: 'rgba(255,255,255,0.9)',
        }
      }
      return {}
    },

  },
  mounted() {
    defineSlice({
      slots: [{ name: 'default' }],
      description: 'Text can be on the right or left with a large image in the background',
      fields: {
        title: { type: 'text', label: 'Title', editorLabel: true },
        description: { type: 'wysiwyg', label: 'Description' },
        whitewash: { type: 'checkbox', label: 'White Wash Description', default: true },
        contentOnLeft: { type: 'checkbox', label: 'Content on Left?', instructions: '"Content on right by default"', default: false },
        backgroundImage: { type: 'media', label: 'Background Image', breakpoints: { lg: { width: 1200, height: 600 }, md: { width: 500, height: 501 } } },
        showMotif: { label: 'Show Motif', type: 'checkbox', defaults: { checked: false } },
        maxWidth: { label: 'Max Width', instruction: 'Leave blank for default styles. Number only. Do not include units.', type: 'text' },
      },
      name: { label: 'Background Image Content Block', group: 'Hyatt' },
      templates: [{
        label: 'Background Image Content Block',
        fields: {
          backgroundImage: { value: 'https://playa-cms-assets.s3.amazonaws.com/media/Sanctuary/Sanctuary_Dining/sanctuary-cap-cana-blue-marlin-restaurant-detailed-appetizer.jpg' },
          description: { value: '<p><span style="text-align: center;">Guests may choose from 5 a\u0301 la carte and buffet restaurants featuring indoor or al fresco seating, as well as 24-hour room service. The nearby restaurants at the Luxury Village, which surround Cap Cana\u2019s stunning marina, also provide an array of culinary selections ranging from organic salads to fresh seafood.</span></p>' },
          title: { value: 'DINING & DRINKS' },
          showMotif: { value: true },
        },
      }],
    })
  },
}
</script>

<template>
  <div
    class="container flex flex-col justify-center md:p-8 relative overflow-hidden"
    :style="{
      maxWidth: fields.maxWidth.value ? `${fields.maxWidth.value}px` : 'none',
    }"
  >
    <VoixMedia v-slot="slotProps" :field="fields.backgroundImage" background>
      <div
        class="p-4 md:p-8 py-8 text-center flex items-center bg-cover min-h-96"
        :class="{ 'flex-row-reverse': fields.contentOnLeft.value === false }"
        :style="{ backgroundImage: `url(${slotProps.image})` }"
      >
        <div class="hidden lg:block text-center md:w-1/2 py-12 relative z-10">
          <div class="relative z-10 py-8 md:p-8 space-y-4" :style="whitewashStyles">
            <h4 class="uppercase text-grey-darkest">
              {{ fields.title.value }}
            </h4>
            <div class="leading-loose text-grey-darkest" v-html="fields.description.value" />
            <slot class="mt-8" />
          </div>
        </div>
        <BackgroundElementsSpiderWeb
          v-if="fields.showMotif.value"
          class="text-white opacity-50 absolute z-0"
          :class="{
            'right-0 -mr-16': fields.contentOnLeft.value === true,
            'left-0 -ml-16': fields.contentOnLeft.value === false,
          }"
          w="700px" h="700px"
        />
      </div>
    </VoixMedia>
    <!-- Mobile -->
    <div class="text-center md:w-1/2 pt-4 relative lg:hidden z-10 mx-auto">
      <div class="relative z-10 py-8 md:p-8 space-y-4" :style="whitewashStyles">
        <h4 class="uppercase text-grey-darkest">
          {{ fields.title.value }}
        </h4>
        <div class="leading-loose text-grey-darkest" v-html="fields.description.value" />
        <slot class="mt-8" />
      </div>
    </div>
  </div>
</template>
