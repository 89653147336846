<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    colors() {
      if (this.fields.fgColor.value || this.fields.bgColor.value) {
        if (this.fields.buttonType.value === 'btn-ghost') {
          return {
            color: this.fields.fgColor.value,
            borderColor: this.fields.fgColor.value,
          }
        }
        if (this.fields.buttonType.value === 'btn-text') {
          return {
            color: this.fields.fgColor.value,
          }
        }
        return {
          color: this.fields.fgColor.value,
          backgroundColor: this.fields.bgColor.value,
        }
      }

      return {}
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign({}, { fields: this.fields, page: this.currentPage })
    },

  },
  mounted() {
    defineSlice({
      name: {
        label: 'Learn More Modal',
        group: 'global',
      },
      fields: {
        learnMore: { type: 'wysiwyg', label: 'Modal Copy', name: 'learnMore' },
        link: { type: 'text', label: 'Link Text', instructions: 'The image is the link so the label is ignored.', editorLabel: true, default: 'Learn More', name: 'link' },
        buttonType: {
          type: 'select',
          label: 'Button Type',
          options: {
            'btn': 'Button',
            'btn btn-ghost': 'Ghost Button',
            'btn btn-text': 'Regular Link',
          },
          name: 'buttonType',
        },
        fgColor: { type: 'color', label: 'Text Color', name: 'fgColor' },
        bgColor: { type: 'color', label: 'Background Color', name: 'bgColor' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <learn-more :learn-more="fields.learnMore.value" :devise="devise">
    <button class="mb-2" :class="[fields.buttonType.value]" :style="colors">
      {{ fields.link.value }}
    </button>
  </learn-more>
</template>
