<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isHovered: false,
    }
  },
  computed: {
    widthClass() {
      if (this.$voix.breakpoint === 'md' || this.$voix.breakpoint === 'lg' || this.$voix.breakpoint === 'xl')
        return this.fields.width.value
      else
        return 'w-full'
    },
    currentStyle() {
      if (this.isHovered)
        return `color: ${this.fields.backgroundColor.value}; background-color:${this.fields.foregroundColor.value}`

      return `color:${this.fields.foregroundColor.value}; background-color:${this.fields.backgroundColor.value}`
    },

  },
  mounted() {
    defineSlice({
      preview: 'SlicesGlobalGalleriesGridGalleryElementsContentBox.jpg',
      description: 'Content box in the grid gallery slides out a bunch of information when clicked. Colors can be customized on the box.',
      fields: { title: { type: 'text', label: 'Title', editorLabel: true }, largeImage: { type: 'media', label: 'Large Image' }, content: { type: 'wysiwyg', label: 'Content' }, foregroundColor: { type: 'color', label: 'Foreground Color', default: '#00bdc6' }, backgroundColor: { type: 'color', label: 'Background Color', default: '#FEFFFF' }, width: { type: 'select', label: 'Width', options: { 'w-1\/4': 'Small (1\/4)', 'w-1\/2': 'Medium (1\/2)', 'w-3\/4': 'Large (3\/4)', 'w-full': 'Full (1\/1)' } }, category: { label: 'Category', type: 'select', options: { 'category-resort': 'Resort', 'category-activities': 'Activities', 'category-pools': 'Pools', 'category-rooms': 'Rooms', 'category-dining': 'Dining', 'category-celebrations': 'Celebrations', 'category-meetings': 'Meetings' } } },
      name: { label: 'Grid Gallery Content Box', group: 'Global' },
      templates: [
        {
          label: 'Content Box',
          fields: {
            title: { value: 'Title' },
            width: { value: 'w-1\/4' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div :class="widthClass" :style="currentStyle" @mouseleave="isHovered = false" @mouseover="isHovered = true" @click="loadSidebar()">
    <div class="flex items-center justify-center p-8 grid-item-inner font-bold">
      <div>
        {{ fields.title.value }}
      </div>
    </div>
  </div>
</template>
