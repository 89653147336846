<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Two columns of cards with images, title, description, and a link',
      fields: {
        image: { type: 'media', label: 'Image 1', breakpoints: { default: { width: 300, height: 300 } } },
        title: { type: 'text', label: 'Title 1' },
        description: { type: 'wysiwyg', label: 'Description 1' },
        link: { type: 'link', label: 'Link 1' },
        image2: { type: 'media', label: 'Image 2', breakpoints: { default: { width: 300, height: 300 } } },
        title2: { type: 'text', label: 'Title 2' },
        description2: { type: 'wysiwyg', label: 'Description 2' },
        link2: { type: 'link', label: 'Link 2' },
      },
      name: { label: 'Dual Image Cards', group: 'Wyndham' },
      templates: [{
        label: 'Dual Image Cards',
        fields: { description: { value: '<p>Circuito de zip-line (tirolesa) adicional gratuito con la compra de ATV Xtreme &amp; Tulum Adventure, disponible los martes y jueves.<\/p>' }, description2: { value: '<p>Acceso gratuito a caverna adicional con nataci\u00F3n con la compra de Mayan Adventure, disponible los lunes y viernes.<\/p>' }, image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham\/excursion\/aventuras-mayas-510.jpg' }, image2: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/wyndham\/excursion\/cavern-510.jpg' }, title: { value: 'Excursi\u00F3n zip-line gratis' }, title2: { value: 'Excursi\u00F3n caverna gratis' } },
      }],
    })
  },
}
</script>

<template>
  <div class="flex  justify-center dual-image-cards bg-grey-lightest py-12">
    <div class="container flex flex-col lg:flex-row">
      <div class="w-full lg:w-1/2 p-6 flex items-stretch">
        <div class="bg-white flex flex-col-reverse lg:flex-row p-4">
          <div class="w-full lg:w-1/2 pr-4 pl-2 py-8">
            <div class="title text-xl mb-2" v-html="fields.title.value" />
            <div class="description font-light text-sm Kanit" v-html="fields.description.value" />
            <a class="text-sm Kanit" :href="fields.link.value?.href" v-html="fields.link.value?.text" />
          </div>
          <div class="w-full lg:w-1/2">
            <VoixMedia v-slot="slotProps" class="w-full" :field="fields.image" background>
              <div
                class="w-full bg-cover bg-center" style="min-height:295px; aspect-ratio: 1 / 1;"
                :style="{ backgroundImage: `url(${slotProps.image})` }"
              />
            </VoixMedia>
          </div>
        </div>
      </div>

      <div class="w-full lg:w-1/2 p-6 flex items-stretch">
        <div class="bg-white flex flex-col-reverse lg:flex-row p-4">
          <div class="w-full lg:w-1/2 pr-4 pl-2 py-8">
            <div class="title text-xl mb-2" v-html="fields.title2.value" />
            <div class="description font-light text-sm Kanit" v-html="fields.description2.value" />
            <a class="text-sm Kanit" :href="fields.link2.value?.href" v-html="fields.link2.value?.text" />
          </div>
          <div class="w-full lg:w-1/2">
            <VoixMedia v-slot="slotProps" class="w-full" :field="fields.image2" background>
              <div
                class="w-full bg-cover bg-center" style="min-height:295px; aspect-ratio: 1 / 1;"
                :style="{ backgroundImage: `url(${slotProps.image})` }"
              />
            </VoixMedia>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
