<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    imageStyle() {
      if (this.fields.maxWidth.value && this.$voix.breakpoint !== 'default')
        return { 'max-width': `${this.fields.maxWidth.value}px` }

      return {}
    },

  },
  mounted() {
    defineSlice({
      fields: {
        maxWidth: { type: 'text', label: 'Max Width (100% if 0)' },
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 800 } } },
      },
      name: { label: 'Image', group: 'Global' },
      templates: [{
        label: 'Image',
        fields: {
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/awards\/sanctuary\/tripadvisor115x100.png' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="text-center">
    <VoixMedia :field="fields.image" class="w-full xl:w-auto mx-auto" :style="imageStyle" />
  </div>
</template>
