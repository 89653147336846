<script>
export default {
  props: {
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsPackagesSlider.jpg',
      slots: [{ name: 'default' }],
      description: 'Weddings Packages Slider',
      fields: {},
      name: {
        label: 'Weddings Packages Slider',
        group: 'Weddings',
      },
      templates: [],
    })
  },
}
</script>

<template>
  <GeneralSwiper class="w-full">
    <VoixSlice
      v-for="s in slice.elements"
      :key="s.id"
      :slice="s"
    />

    <template #previous>
      <IconsLeftArrow v-if="slice.elements && slice.elements.length > 4" color="#6e7275" :w="20" :h="20" />
      <div v-else />
    </template>
    <template #next>
      <IconsRightArrow v-if="slice.elements && slice.elements.length > 4" color="#6e7275" :w="20" :h="20" />
      <div v-else />
    </template>
  </GeneralSwiper>
</template>
