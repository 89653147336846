<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  computed: {
    paddingTop() {
      return this.$voix.breakpoint !== 'default' ? this.fields.paddingTop.value : Math.round(Number.parseInt(this.fields.paddingTop.value) * 0.5)
    },
    paddingBottom() {
      return this.$voix.breakpoint !== 'default' ? this.fields.paddingBottom.value : Math.round(Number.parseInt(this.fields.paddingBottom.value) * 0.5)
    },

  },
  mounted() {
    defineSlice({
      name: { label: 'Four Oh Four', group: 'global' },
      preview: 'SlicesGlobalErrorsFourOhFour.jpg',
      fields: { title: { type: 'text', label: 'Title', name: 'title' }, copy: { type: 'wysiwyg', label: 'Copy', name: 'copy' }, backgroundImage: { type: 'media', label: 'Background Image', breakpoints: { lg: { width: 1800, height: 1200 }, sm: { width: 600, height: 600 } }, name: 'backgroundImage' }, paddingTop: { type: 'number', label: 'Desktop Padding Top', name: 'paddingTop' }, paddingBottom: { type: 'number', label: 'Desktop Padding Bottom', name: 'paddingBottom' } },
      slots: [],
    })
  },
}
</script>

<template>
  <div>
    <VoixMedia
      v-slot="slotProps"
      :field="fields.backgroundImage"
      :background="true"
    >
      <div
        class="flex flex-col items-center bg-left-bottom bg-cover text-center text-abs-white"
        :style="{ paddingTop: `${paddingTop}px`, paddingBottom: `${paddingBottom}px`, backgroundImage: `url(${slotProps.image})` }"
      >
        <div class="text-3xl uppercase title text-white mb-8">
          {{ fields.title.value }}
        </div>
        <div class="wysiwyg w-3/5" v-html="fields.copy.value" />
      </div>
    </VoixMedia>
  </div>
</template>
