<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        { fields: this.fields, page: this.currentPage },
      )
    },
  },

  mounted() {
    defineSlice({ name: { label: 'Left Right Image With Text', group: 'playaresorts' }, fields: { tagline: { type: 'text', label: 'Tagline', enabled: false, name: 'tagline' }, title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' }, copy: { type: 'wysiwyg', label: 'Copy', enabled: false, name: 'copy' }, buttonOne: { type: 'link', label: 'Button One', enabled: false, name: 'buttonOne' }, buttonTwo: { type: 'link', label: 'Button Two', enabled: false, name: 'buttonTwo' }, left: { type: 'checkbox', label: 'Text on left', default: true, name: 'left' }, included: { type: 'text', label: 'Included Banner Text', default: 'Included', enabled: false, name: 'included' }, includedBackground: { type: 'color', label: 'Included Banner BG Color', name: 'includedBackground' }, includedForeground: { type: 'color', label: 'Included Banner FG Color', name: 'includedForeground' }, image: { type: 'media', label: 'Image 1', breakpoints: { lg: { width: 651, height: 450 } }, name: 'image' }, image2: { type: 'media', label: 'Image 2', breakpoints: { lg: { width: 651, height: 450 } }, enabled: false, name: 'image2' }, image3: { type: 'media', label: 'Image 3', breakpoints: { lg: { width: 651, height: 450 } }, enabled: false, name: 'image3' }, image4: { type: 'media', label: 'Image 4', breakpoints: { lg: { width: 651, height: 450 } }, enabled: false, name: 'image4' }, image5: { type: 'media', label: 'Image 5', breakpoints: { lg: { width: 651, height: 450 } }, enabled: false, name: 'image5' } }, slots: [{ name: 'default', allowedElements: [] }] })
  },
}
</script>

<template>
  <div class="left-right-block-suite">
    <div
      class="flex justify-center bg-cover bg-center w-full left-right-image-with-text"
    >
      <div class="md:p-10 container overflow-hidden relative">
        <div
          class="flex md:flex-row items-stretch md:-mx-8 flex-col-reverse bg-white pb-8 md:pb-0"
          :class="{ 'md:flex-row-reverse': fields.left.value === true }"
        >
          <div class="md:w-1/2 p-10 pb-0 text-left">
            <p v-if="fields.tagline.enabled" class="text-lg pb-1 wysiwyg">
              {{ fields.tagline.value }}
            </p>
            <div class="text-3xl uppercase title font-normal">
              {{ fields.title.value }}
            </div>
            <div
              v-if="fields.copy.enabled"
              class="my-4 prose"
              v-html="fields.copy.value"
            />
            <a
              v-if="fields.buttonOne.enabled"
              :href="fields.buttonOne.value.href"
              class="btn btn-ghost inline-block m-2"
            >
              {{ fields.buttonOne.value.text }}
            </a>
            <a
              v-if="fields.buttonTwo.enabled"
              :href="fields.buttonTwo.value.href"
              class="btn btn-ghost inline-block m-2"
            >
              {{ fields.buttonTwo.value.text }}
            </a>

            <div>
              <slot />
            </div>
          </div>
          <div class="flex items-center md:w-1/2 md:p-4 text-center">
            <GeneralSwiper :options="{ lazy: true }" :devise="devise">
              <VoixMedia :field="fields.image" />
              <VoixMedia v-if="fields.image2.value && fields.image2.value.length" :field="fields.image2" />
              <VoixMedia v-if="fields.image3.value && fields.image3.value.length" :field="fields.image3" />
              <VoixMedia v-if="fields.image4.value && fields.image4.value.length" :field="fields.image4" />
              <VoixMedia v-if="fields.image5.value && fields.image5.value.length" :field="fields.image5" />
            </GeneralSwiper>

            <div v-if="fields.included.enabled" class="included absolute">
              <div
                class="flex justify-center items-center uppercase text-sm"
                :style="{
                  backgroundColor: fields.includedBackground.value,
                  color: fields.includedForeground.value,
                }"
              >
                <div class="font-bold">
                  {{ fields.included.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
