<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  components: {
    GeneralSwiper: () => import('@/components/GeneralSwiper'),
  },
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    overrideStyles() {
      if (this.fields.includedBackground) {
        return {
          backgroundColor: this.fields.includedBackground.color,
          color: this.fields.includedForeground.color,
        }
      }
      return {}
    },
    currentPage() {
      const pageStore = usePageStore()
      return pageStore.currentPage
    },
    devise() {
      return Object.assign(
        {},
        { fields: this.fields, page: this.currentPage },
        this.$voix,
      )
    },
  },
  mounted() {
    defineSlice({
      name: {
        label: 'Slider Image Card',
        group: 'global',
      },
      fields: {
        title: {
          type: 'text',
          label: 'Title',
          editorLabel: true,
          name: 'title',
        },
        content: { type: 'wysiwyg', label: 'Content', name: 'content' },
        image1: { type: 'media', label: 'Image 1', breakpoints: { default: { width: 510, height: 360 } }, name: 'image1' },
        image2: { type: 'media', label: 'Image 2', breakpoints: { default: { width: 510, height: 360 } }, name: 'image2' },
        image3: { type: 'media', label: 'Image 3', breakpoints: { default: { width: 510, height: 360 } }, name: 'image3' },
        image4: { type: 'media', label: 'Image 4', breakpoints: { default: { width: 510, height: 360 } }, name: 'image4' },
        included: { type: 'text', label: 'Included Banner Text', default: 'Included', enabled: false, name: 'included' },
        includedBackground: { type: 'color', label: 'Included Banner BG Color', name: 'includedBackground' },
        includedForeground: { type: 'color', label: 'Included Banner FG Color', name: 'includedForeground' },
      },
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="mb-4 mx-4">
    <div class="bg-abs-white shadow rounded h-full">
      <div class="relative">
        <GeneralSwiper>
          <VoixMedia :field="fields.image1" class="w-full aspect-[16/10]" />
          <VoixMedia
            v-if="fields.image2.value && fields.image2.value.length > 0"
            :field="fields.image2"
            class="w-full aspect-[16/10]"
          />
          <VoixMedia
            v-if="fields.image3.value && fields.image3.value.length > 0"
            :field="fields.image3"
            class="w-full aspect-[16/10]"
          />
          <VoixMedia
            v-if="fields.image4.value && fields.image4.value.length > 0"
            :field="fields.image4"
            class="w-full aspect-[16/10]"
          />
        </GeneralSwiper>

        <div v-if="fields.included.enabled" class="included absolute">
          <div
            class="flex justify-center items-center uppercase text-sm text-abs-white bg-currentsite-background"
            :style="overrideStyles"
          >
            <div class="font-bold">
              {{ fields.included.value }}
            </div>
          </div>
        </div>

        <div class="p-8">
          <h2 class="mb-6 text-playa-action text-xl font-light">
            {{ fields.title.value }}
          </h2>
          <div class="wysiwyg" v-html="fields.content.value" />

          <div class="mt-8">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
