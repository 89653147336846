<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    loginUrl() {
      const config = useRuntimeConfig()
      return `${config.public.voixApiBaseUrl}/handbook-login`
    },
    hasErrorInUrl() {
      if (process.client)
        return window.location.search.includes('error')

      return false
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Handbook Login', group: 'global' },
      preview: 'SlicesGlobalFormsHandbookLogin.jpg',
      fields: { title: { type: 'text', label: 'Title', editorLabel: true, name: 'title' }, introduction: { type: 'wysiwyg', label: 'Introduction', name: 'introduction' }, backgroundImage: { type: 'media', label: 'Background Image', breakpoints: { default: { width: 1200 } }, name: 'backgroundImage' } },
      slots: [],
    })
  },
}
</script>

<template>
  <VoixMedia
    v-slot="slotProps"
    :field="fields.backgroundImage"
    :background="true"
  >
    <div
      class="flex justify-center pt-48 pb-64 bg-cover"
      :style="{
        backgroundImage: `url(${slotProps.image})`,
      }"
    >
      <div
        class="py-12 p-12 md:p-12 container text-center flex flex-col items-center"
      >
        <div class="mb-8 w-3/4 titles flex flex-col items-center">
          <div
            class="text-white text-3xl uppercase font-light"
            v-html="fields.title.value"
          />
        </div>
        <div
          class="text-white wysiwyg"
          v-html="fields.introduction.value"
        />

        <form
          method="post"
          :action="loginUrl"
          class="mt-6 pb-4 px-8 bg-abs-white"
        >
          <input class="border" name="email" type="hidden" value="handbook.user@playaresorts.com">

          <div
            class="flex min-w-[500px] justify-center flex-col md:flex-row items-stretch -mx-8"
          >
            <div
              class="w-2/3 flex flex-col relative mb-10 md:mb-0 items-stretch md:w-1/3 lg:w-1/3 mx-4"
            >
              <fieldset class="mt-4 text-left">
                <label>Password</label>
                <input class="border" type="password" name="password">
              </fieldset>
            </div>

            <div
              class="w-1/3 flex flex-col relative mb-10 md:mb-0 items-stretch md:w-1/3 lg:w-1/3 mx-4"
            >
              <button type="submit" class="mt-10 py-2 raised clear bg-playa-action text-white">
                Submit
              </button>
            </div>
          </div>
          <div
            v-if="hasErrorInUrl"
            class="text-left text-red text-white mt-4 mb-2 -ml-4"
          >
            Username or password is incorrect
          </div>
        </form>
      </div>
    </div>
  </VoixMedia>
</template>
