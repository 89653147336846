<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  mounted() {
    defineSlice({ description: 'Navy block on the left or right with content over an image', fields: { image: { label: 'Image', type: 'media', breakpoints: { lg: { width: 950, height: 530 }, sm: { width: 450, height: 500 } } }, title: { label: 'Title', type: 'text' }, copy: { label: 'Copy', type: 'wysiwyg' }, link: { label: 'Link', type: 'link', enabled: false }, onRight: { label: 'Copy on Right?', type: 'checkbox' } }, name: { label: 'Navy Block Overlay', group: 'Hyatt' }, templates: [{ label: 'Navy Block Overlay', fields: { copy: { value: '<p>Surrounded by lush mangroves and perched on a long expanse of private white-sand beach, Hyatt Zilara Riviera Maya invites groups to a sophisticated oasis to escape life\u2019s daily rigors. With modern accommodations, gourmet dining, a sophisticated adult atmosphere and over 6,000 square feet of modern conference facilities, Hyatt Zilara Riviera Maya is the ideal oceanfront setting for corporate meetings and incentive trips, with every aspect designed for success.&nbsp;<\/p>' }, image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Zilara_Riviera_Maya\/2023\/hyatt-zilara-riviera-maya-mice-amber-breakout-room-1.jpg' }, title: { value: 'Memorable Meetings' } } }] })
  },
  methods: {
    visibilityChanged(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.isVisible = true
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: visibilityChanged, threshold: 1 }"
    class="mt-6 md:mt-0 relative container mx-auto flex flex-col-reverse lg:flex-row overflow-hidden"
    :class="{
      'justify-start': !fields.onRight.value,
      'justify-end': fields.onRight.value,
    }"
  >
    <div
      class="relative z-10 lg:w-1/3 bg-zilara-navy text-white font-light px-12 py-24 transition-all duration-[1.5s] ease-out"
      :class="{
        'lg:-translate-x-64': !isVisible && !fields.onRight.value,
        'lg:translate-x-64': !isVisible && fields.onRight.value,
        'lg:translate-x-0': isVisible,
      }"
      style="filter: drop-shadow(3px 6px 3px rgba(50, 50, 0, 0.3))"
    >
      <h3 class="text-3xl uppercase">
        {{ fields.title.value }}
      </h3>
      <div class="mt-4 font-extralight" v-html="fields.copy.value" />
      <a
        v-if="fields.link.enabled"
        :href="fields.link.value?.href"
        :target="fields.link.value.target"
        class="inline-block mt-8 px-8 pt-4 pb-3 rounded-full uppercase font-light bg-white text-zilara-navy"
      >
        {{ fields.link.value.text }}
      </a>
    </div>

    <div
      class="lg:absolute z-0 lg:top-0 lg:bottom-0 lg:w-3/4 lg:py-12"
      :class="{
        'lg:left-0': fields.onRight.value,
        'lg:right-0': !fields.onRight.value,
      }"
    >
      <div
        class="relative w-full h-full transition-all duration-[1.5s] ease-out"
        :class="{
          'lg:translate-x-64': !isVisible && !fields.onRight.value,
          'lg:-translate-x-64': !isVisible && fields.onRight.value,
          'lg:translate-x-0': isVisible,
        }"
      >
        <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
      </div>
    </div>
  </div>
</template>
