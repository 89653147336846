<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      fields: {
        title: { type: 'text', label: 'Title' },
        description: {
          type: 'wysiwyg',
          label: 'Description',
        },
      },
      name: { label: 'Grid Item Title Description', group: 'Playa Collection' },
      templates: [{ label: 'Grid Item Title Description', fields: { description: { value: '<p>Exchange access to <strong>4200 affiliated resorts <\/strong>in more than <strong>110 countries. <br><\/strong>Access to more than&nbsp;<strong>900,000 hotels&nbsp;<\/strong>worldwide with significant discounts.<\/p>' }, title: { value: 'TRAVEL THE WORLD' } } }],
    })
  },
}
</script>

<template>
  <div class="px-10">
    <div class="font-bold text-sm mb-2">
      {{ fields.title.value }}
    </div>
    <div class="text-sm" v-html="fields.description.value" />
  </div>
</template>
