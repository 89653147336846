<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Hyatt Small Image With Small Copy',
      fields: { image: { label: 'Image', type: 'media', breakpoints: { default: { width: 500, height: 500 } } }, title: { label: 'Title', type: 'text' }, copy: { label: 'Copy', type: 'wysiwyg' }, link: { label: 'Link', type: 'link', enabled: false } },
      name: { label: 'Small Image Small Copy', group: 'Hyatt' },
      templates: [{
        label: 'Small Image Small Copy',
        fields: {
          copy: { value: '<p>Gastronomy takes center stage at Hyatt Zilara Riviera Maya, where our chefs tell a story with every dish they create. Here, the dining experiences tempt all senses whether it\u2019s a smoky mezcal cocktail from our beach club or dinner (and a show) at a teppanyaki table. Satisfy any craving any time with complimentary room service or pursue something sensational and new at one of our nine restaurants, bars, and lounges.<\/p>' },
          image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/hyatt_zilara_rm\/dining\/hyatt-ziva-los-cabos-bon-vivant-restaurant-fb-dessert-cacao.jpg' },
          link: { value: { text: 'EXPLORE DINING & DRINKS', target: '_self', relationship: null, href: '\/new-page' } },
          title: { value: 'CUISINE WORTH CELEBRATING' },
        },
      }],
    })
  },
}
</script>

<template>
  <div class="py-24">
    <div
      class="container mx-auto px-8 md:px-16 xl:px-56 flex flex-col md:flex-row items-center"
    >
      <div class="w-full md:w-3/5">
        <VoixMedia :field="fields.image" class="object-cover w-full h-full" />
      </div>
      <div class="w-full md:w-3/5 md:pl-12 mt-12 md:mt-0 font-light text-center">
        <h3 class="text-3xl">
          {{ fields.title.value }}
        </h3>
        <div class="mt-4" v-html="fields.copy.value" />
        <a
          v-if="fields.link.enabled"
          :href="fields.link.value?.href"
          class="inline-block mt-4 px-8 pt-4 pb-3 rounded-full uppercase font-light border-gray-300 border hover:bg-zilara-navy text-zilara-navy hover:text-white transition-colors duration-500"
        >
          {{ fields.link.value.text }}
        </a>
      </div>
    </div>
  </div>
</template>
