<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      mounted: false,
    }
  },
  computed: {
    heroStyles() {
      if (this.$voix.breakpoint.value === 'sm') {
        return {
          backgroundAttachment: this.fields.parallax.checked ? 'inherit' : '',
          minHeight: '500px',
          backgroundImage: `url('${this.currentImage}')`,
        }
      }
      else {
        return {
          backgroundAttachment: this.fields.parallax.checked ? 'fixed' : '',
          minHeight: '760px',
          backgroundImage: `url('${this.currentImage}')`,
        }
      }
    },

    currentImage() {
      if (this.$voix.breakpoint.isGreaterThan('md'))
        return this.fields.image.media.find(x => x.breakpoint === 'lg').url

      return this.fields.image.media.find(x => x.breakpoint === 'default').url
    },

    titleStyles() {
      const styles = {}

      // hacky shhh to make titleStyles to redraw on mounted
      // eslint-disable-next-line
      const mounted = this.mounted

      styles.width = `${this.fields.titleWidth.value}px`

      if (this.$voix.breakpoint.isGreaterThan('lg')) {
        styles.fontSize = '72px'
        styles.width = `${this.fields.titleWidth.value}px`
        styles.top = `${this.fields.titlePositionY.value}px`
        styles.left = `${this.fields.titlePositionX.value}px`
      }
      else if (this.$voix.breakpoint.isGreaterThan('md')) {
        styles.fontSize = '48px'
        styles.width = `${this.fields.mediumTitleWidth.value}px`
        styles.top = `${this.fields.mediumTitlePositionY.value}px`
        styles.left = `${this.fields.mediumTitlePositionX.value}px`
      }
      else {
        styles.fontSize = '24px'
        styles.width = `${this.fields.mobileTitleWidth.value}px`
        styles.top = `${this.fields.mobileTitlePositionY.value}px`
        styles.left = `${this.fields.mobileTitlePositionX.value}px`
      }

      return styles
    },
  },

  mounted() {
    defineSlice({ name: { label: 'Title Description', group: 'playa-collection' }, fields: { image: { type: 'media', label: 'Image', breakpoints: { lg: { width: 1600, height: 760 }, default: { width: 600, height: 580 } }, name: 'image' }, imageCitation: { type: 'text', label: 'Image Citation', name: 'imageCitation' }, title: { type: 'text', label: 'Title', name: 'title' }, link: { type: 'link', label: 'Link', enabled: true, name: 'link' }, parallax: { type: 'checkbox', label: 'Parallax On', name: 'parallax' }, titleWidth: { type: 'number', label: 'Title Width', name: 'titleWidth' }, titlePositionX: { type: 'number', label: 'Title Position X', name: 'titlePositionX' }, titlePositionY: { type: 'number', label: 'Title Position Y', name: 'titlePositionY' }, mediumTitleWidth: { type: 'number', label: 'Medium Title Width', name: 'mediumTitleWidth' }, mediumTitlePositionX: { type: 'number', label: 'Medium Title Position X', name: 'mediumTitlePositionX' }, mediumTitlePositionY: { type: 'number', label: 'Medium Title Position Y', name: 'mediumTitlePositionY' }, mobileTitleWidth: { type: 'number', label: 'mobile Title Width', name: 'mobileTitleWidth' }, mobileTitlePositionX: { type: 'number', label: 'mobile Title Position X', name: 'mobileTitlePositionX' }, mobileTitlePositionY: { type: 'number', label: 'mobile Title Position Y', name: 'mobileTitlePositionY' } }, slots: [{ name: 'default', allowedElements: [] }] })

    this.mounted = true
  },
}
</script>

<template>
  <div class="w-full relative leading-none bg-cover" :style="heroStyles">
    <div class="absolute inset-0 flex justify-center">
      <div class="container relative">
        <div
          class="absolute font-sans-lato font-extrabold text-white font-light uppercase"
          :style="titleStyles"
        >
          {{ fields.title.value }}
          <div class="mt-2">
            <a
              v-if="fields.link.href && fields.link"
              class="text-sm font-normal normal-case text-white flex items-center justify-start"
              :href="fields.link.href"
              :target="fields.link.target"
            >
              {{ fields.link.text }}
              <svg
                class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div
        class="absolute right-0 bottom-0 mb-4 mr-4 md:mb-8 md:mr-8 uppercase text-sm text-white z-10 opacity-75"
      >
        {{ fields.imageCitation.value }}
      </div>
    </div>
  </div>
</template>
