<script>
import { mapActions } from 'pinia'
import { useBookingWidgetStore } from '@/store/bookingWidgetStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({ name: { label: 'Promo Coupon', group: 'global' }, fields: { promo: { type: 'text', label: 'Promo Code', editorLabel: true, name: 'promo' }, coupon: { type: 'text', label: 'Coupon Code', name: 'coupon' }, rate: { type: 'text', label: 'Rate Code', name: 'rate' }, group: { type: 'text', label: 'Group Code', name: 'group' }, configcode: { type: 'text', label: 'Config Code', default: 'PlayaWebsite', name: 'configcode' }, themecode: { type: 'text', label: 'Theme Code', default: 'PlayaWebsite', name: 'themecode' }, currency: { type: 'text', label: 'Currency', default: 'USD', name: 'currency' } }, slots: [] })

    this.setPromoCodes({
      promo: this.fields.promo.value,
      coupon: this.fields.coupon.value,
      rate: this.fields.rate.value,
      group: this.fields.group.value,
      configcode: this.fields.configcode.value,
      themecode: this.fields.themecode.value,
      currency: this.fields.currency.value,
    })
  },
  methods: {
    ...mapActions(useBookingWidgetStore, ['setPromoCodes']),
  },
}
</script>

<template>
  <div />
</template>
