<script>
import anime from 'animejs/lib/anime.es.js'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    numberOfCards() {
      let numberOfCards = 0

      if (this.fields.card1Image.value)
        numberOfCards += 1

      if (this.fields.card2Image.value)
        numberOfCards += 1

      if (this.fields.card3Image.value)
        numberOfCards += 1

      return numberOfCards
    },
  },
  mounted() {
    defineSlice({
      fields: {
        title: { type: 'text', label: 'Title' },
        description: { type: 'wysiwyg', label: 'Description' },
        card1Image: { type: 'media', label: 'Card 1 Image', breakpoints: { lg: { width: 500, height: 300 } } },
        card1Title: { type: 'text', label: 'Card 1 Title' },
        card1Description: { type: 'wysiwyg', label: 'Card 1 Description' },
        card2Image: { type: 'media', label: 'Card 2 Image', breakpoints: { lg: { width: 500, height: 300 } } },
        card2Title: { type: 'text', label: 'Card 2 Title' },
        card2Description: { type: 'wysiwyg', label: 'Card 2 Description' },
        card3Image: { type: 'media', label: 'Card 3 Image', breakpoints: { lg: { width: 500, height: 300 } } },
        card3Title: { type: 'text', label: 'Card 3 Title' },
        card3Description: { type: 'wysiwyg', label: 'Card 3 Description' },
      },
      name: { label: 'Three Image Cards', group: 'Playa Collection' },
      templates: [{
        label: 'Three Image Cards',
        fields: {
          card1Description: { value: '<p>Explore these ancient lands \nand exotic flavors. Discover the \nsand, sun and culture of this\n beautiful country.<\/p>' },
          card1Image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_ziva_cancun\/hyatt-ziva-cancun-aerial-10.jpg' },
          card1Title: { value: 'MEXICO' },
          card2Description: { value: '<p>Come to a place where the Caribbean Sea \nchurns with the Atlantic Ocean. Where the\n lush vegetation compliments the \nwarmth of an island world. Find yourself \nlost in the rhythm of bachata!<\/p>' },
          card2Image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/playacollection\/domrepublic.jpg' },
          card2Title: { value: 'DOMINICAN REPUBLIC' },
          card3Description: { value: '<p>Feel the poetic world of this \nexuberant folklore island pulsing\n with magical ambiance and reggae. \nWhere else can you watch the sunrise \nover the sea and the sunset casting\n shadows on the misty mountains.\n<\/p>' },
          card3Image: { value: 'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hyatt_Ziva_Rose_Hall\/ttd\/2021\/hyatt-ziva-rose-hall-swimming2.jpg' },
          card3Title: { value: 'JAMAICA' },
          description: { value: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris lacinia, orci at semper finibus, tortor elit laoreet eros, in rutrum mauris eros at magna. Cras eleifend consequat nibh in dapibus. ' },
          title: { value: 'Three Image Cards' },
        },
      }],
    })

    anime({
      targets: '.three-image-cards.card',
      translateY: 100,
      opacity: 0,
      duration: 0,
    })
  },
  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0) {
        this.isVisible = true
        anime({
          targets: '.three-image-cards.card',
          translateY: 0,
          opacity: 1,
          duration: 5000,
          easing: 'easeOutExpo',
          delay: anime.stagger(200),
        })
      }
      else {
        this.isVisible = false
        anime({
          targets: '.three-image-cards.card',
          translateY: 100,
          opacity: 0,
          duration: 2000,
        })
      }
    },
  },
}
</script>

<template>
  <div
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    class="w-full py-12 flex justify-center three-image-cards"
  >
    <div class="container text-center">
      <h2
        v-if="fields.title.value"
        class="mb-6 text-4xl font-sans-lato font-light"
      >
        {{ fields.title.value }}
      </h2>
      <div class="mb-4 mx-8" v-html="fields.description.value" />
      <div
        class="grid grid-cols-1 lg:grid-cols-3"
      >
        <div
          v-if="fields.card1Image.value"
          class="flex justify-center items-stretch m-8"
        >
          <div
            class="bg-abs-white three-image-cards card flex flex-col justify-start w-full"
          >
            <div class="w-full aspect-video">
              <VoixMedia :field="fields.card1Image" class="w-full h-full object-cover" />
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="text-center p-4">
                <div class="font-bold card-title">
                  {{ fields.card1Title.value }}
                </div>
                <div
                  class="card-body"
                  v-html="fields.card1Description.value"
                />
              </div>
              <div
                v-if="
                  fields.card1Link && fields.card1Link.enabled
                "
                class="card-body p-4"
              >
                <a
                  class="flex items-center text-bold"
                  :href="fields.card1Link.url"
                >
                  {{ fields.card1Link.text }}
                  <svg
                    class="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card2Image.value"
          class="flex justify-center items-stretch m-8"
        >
          <div
            class="bg-abs-white three-image-cards card flex flex-col justify-start"
          >
            <div class="w-full aspect-video">
              <VoixMedia :field="fields.card2Image" class="w-full h-full object-cover" />
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="text-center p-4">
                <div class="font-bold card-title">
                  {{ fields.card2Title.value }}
                </div>
                <div
                  class="card-body"
                  v-html="fields.card2Description.value"
                />
              </div>
              <div
                v-if="
                  fields.card2Link && fields.card2Link.enabled
                "
                class="card-body p-4"
              >
                <a
                  class="flex items-center text-bold"
                  :href="fields.card2Link.url"
                >
                  {{ fields.card2Link.text }}
                  <svg
                    class="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="fields.card3Image.value"
          class="flex justify-center items-stretch m-8"
        >
          <div
            class="bg-abs-white three-image-cards card flex flex-col justify-start w-full"
          >
            <div class="w-full aspect-video">
              <VoixMedia :field="fields.card3Image" class="w-full h-full object-cover" />
            </div>
            <div class="flex-1 flex flex-col justify-between">
              <div class="text-center p-4">
                <div class="font-bold card-title">
                  {{ fields.card3Title.value }}
                </div>
                <div
                  class="card-body"
                  v-html="fields.card3Description.value"
                />
              </div>
              <div
                v-if="
                  fields.card3Link && fields.card3Link.enabled
                "
                class="card-body p-4"
              >
                <a
                  class="flex items-center text-bold"
                  :href="fields.card3Link.url"
                >
                  {{ fields.card3Link.text }}
                  <svg
                    class="w-4 h-4 ml-1"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
