<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      preview: 'SlicesWeddingsTwoColumnIntroduction.jpg',
      description: 'Weddings Two Column Introduction',
      fields: {
        title: {
          type: 'wysiwyg',
          label: 'Title',
          editorLabel: true,
        },
        subtitle: {
          type: 'wysiwyg',
          label: 'Sub-Title',
        },
        introduction: {
          type: 'wysiwyg',
          label: 'Introduction',
        },
      },
      name: {
        label: 'Weddings Two Column Introduction',
        group: 'Weddings',
      },
      templates: [],
    })
  },
}
</script>

<template>
  <div class="flex justify-center py-8 md:p-8">
    <div
      class="py-8 lg:p-12 container text-center flex flex-col md:flex-row justify-between items-center"
    >
      <div class="mb-6 md:mb-0 flex-none text-center md:text-left relative w-full md:w-1/2">
        <div class="h-24 w-full bg-no-repeat bg-center" style="background-image: url('/imgs/weddings/leaves-top.jpeg'); background-size: auto 100%;" />
        <div
          class="text-center font-cursive font-light mb-1 text-4xl md:text-6xl"
          v-html="fields.title.value"
        />
        <h2
          class="text-hyatt-wedding-action -mt-4 text-2xl text-center font-light relative z-10"
          v-html="fields.subtitle.value"
        />
        <div class="h-24 w-full bg-no-repeat bg-center" style="background-image: url('/imgs/weddings/leaves-bottom.jpeg'); background-size: auto 100%;" />
      </div>
      <div class="px-8 text-center md:text-left leading-loose" v-html="fields.introduction.value" />
    </div>
  </div>
</template>
