<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      preview: 'SlicesAgentsPortalHero.jpg',
      description: 'Slice Image',
      fields: {
        image: { type: 'media', label: 'Image', breakpoints: { default: { width: 1400, height: 600 } } },
        title: { type: 'text', label: 'Title' },
        copy: { type: 'wysiwyg', label: 'Copy' },
        showWelcome: { type: 'checkbox', label: 'Show Welcome' },
        showProgramLogo: { type: 'checkbox', label: 'Show Program Logo' },
      },
      name: { label: 'Hero', group: 'Agents Portal' },
      templates: [
        {
          label: 'Hero',
          fields: {
            image: { value: 'https://playa-cms-assets.s3.amazonaws.com/media/Hyatt_Zilara_Riviera_Maya/2023/hyatt-zilara-riviera-maya-mixology-class-1.jpg' },
            title: { value: 'Preferred Agent Portal' },
            copy: { value: '<p><strong>Lorem ipsum dolor sit amet<br></strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ullamcorper mi a fermentum auctor. Quisque commodo ornare ante, at pellentesque neque dignissim non. Maecenas ornare, enim et tristique suscipit, mauris dolor sollicitudin nisl, sed feugiat nisl orci non ex. Curabitur volutpat erat sed euismod elementum. Duis quam lacus, varius ut aliquam vel, pharetra vel felis. Proin egestas volutpat mi. Phasellus ut viverra mauris. Ut et nunc sagittis, congue lacus quis, lacinia mauris. Duis vel orci tristique, venenatis risus ut, ullamcorper ipsum.</p>' },
          },
        },
      ],
    })
  },
}
</script>

<template>
  <div class="relative text-white mb-12">
    <img
      v-if="fields.showProgramLogo.value"
      src="https://playa-cms-assets.s3.amazonaws.com/media/Agents_Portal/2023/acp-program-logo.png" width="300"
      class="absolute absolute-center-x mt-4"
    >

    <VoixMedia :field="fields.image" class="inset-0 z-0 w-full h-full object-cover" />

    <div
      class="absolute inset-0 bg-gradient-to-t from-[#012343]/50 to-[#012343]/75 lg:via-transparent lg:to-transparent pointer-events-none"
    />

    <div class="relative lg:absolute lg:inset-0  py-12 z-10 text-white flex flex-col justify-end">
      <div v-if="fields.showWelcome.value" class="text-xl lg:text-3xl text-center font-thin">
        <span
          class="font-cursive-allison text-2xl lg:text-5xl"
        >Welcome</span> TO PLAYA HOTEL & RESORTS
      </div>
      <div class="text-2xl lg:text-5xl uppercase text-center font-light">
        {{ fields.title.value }}
      </div>
      <div class="mt-4 text-center px-8 lg:px-24 font-light" v-html="fields.copy.value" />
    </div>
  </div>
</template>
