<script>
import { usePageStore } from '@voix/store/pageStore'

export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    defineSlice({
      description: 'Title riding the rainbow.',
      fields: { sansOnLeft: { type: 'text', label: 'San Serif on Left' }, cursive: { type: 'text', label: 'Cursive' }, sansOnRight: { type: 'text', label: 'San Serif on Right' }, rainbowOnLeft: { type: 'checkbox', label: 'Rainbow on Left?', instructions: '"Rainbow on right by default"', default: false }, pink: { type: 'checkbox', label: 'Pink instead of purple?', default: false } },
      name: { label: 'Rainbow Bar', group: 'Hyatt' },
      templates: [{ label: 'Rainbow Bar', fields: { cursive: { value: 'All' }, sansOnLeft: { value: 'It\'s' }, sansOnRight: { value: 'Included' } } }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center items-center md:items-end relative pt-2" :class="{ 'bg-zilara-cancun-purple': !fields.pink.value, 'bg-ziva-pink': fields.pink.value }">
    <background-elements-right-blue-to-yellow v-if=" $voix.breakpoint !== 'default' && !fields.rainbowOnLeft.value && !fields.pink.value" class="absolute top-0 right-0" w="420px" height="420px" />
    <background-elements-right-blue-to-pink v-if=" $voix.breakpoint !== 'default' && !fields.rainbowOnLeft.value && fields.pink.value" class="absolute top-0 right-0" w="420px" height="420px" />
    <background-elements-left-blue-to-yellow v-if=" $voix.breakpoint !== 'default' && fields.rainbowOnLeft.value && !fields.pink.value" class="absolute top-0 left-0" w="420px" height="420px" />
    <background-elements-left-blue-to-pink v-if=" $voix.breakpoint !== 'default' && fields.rainbowOnLeft.value && fields.pink.value" class="absolute top-0 left-0" w="420px" height="420px" />

    <div class="flex items-center">
      <span class="uppercase text-abs-white text-xl tracking-wider mb-4 block">{{ fields.sansOnLeft.value }}</span>
      <span class="font-cursive text-abs-white mx-2 text-4xl md:text-8xl opacity-50 leading-none block">{{ fields.cursive.value }}</span>
      <span class="uppercase text-abs-white text-xl tracking-wider mb-4 block">{{ fields.sansOnRight.value }}</span>
    </div>
  </div>
</template>
