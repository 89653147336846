<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      description: 'Infinity Luxury Text Slice',
      fields: { line1: { type: 'wysiwyg', label: 'Line 1' }, line2: { type: 'wysiwyg', label: 'Line 2' } },
      name: { label: 'Infinity Text Slice', group: 'Infinity Luxury' },
      templates: [{ label: 'Infinity Text Slice', fields: { line1: { value: '<p>The easiest way to fulfill<\/p>' }, line2: { value: '<p>your globetrotter dreams.<\/p>' } } }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center bg-infinity-action text-white p-8 lg:p-16">
    <div class="max-w-[1000px] w-full flex flex-col items-center">
      <div class="text-3xl lg:text-6xl -ml-8 lg:-ml-32 leading-snug" v-html="fields.line1.value" />
      <div class="mt-4 lg:mt-8 text-3xl lg:text-6xl ml-8 lg:ml-32 leading-snug" v-html="fields.line2.value" />
    </div>
  </div>
</template>
