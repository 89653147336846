<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({ fields: { title: { type: 'text', label: 'Title' }, description: { type: 'wysiwyg', label: 'Description' }, link: { type: 'link', label: 'Link' } }, name: { label: 'Call To Action', group: 'Playa Collection' }, templates: [{ label: 'Call To Action', fields: { description: { value: '<p>We are at your service to help you explore traveling the globe with World Class choices.<br>To find out if our concept of travel is the right fit for you, simply request more information about The Playa Collection\u2122.<\/p>' }, link: { value: { text: 'REQUEST INFO', target: '_self', relationship: null, href: '\/new-page' } }, title: { value: 'Your Journey Starts with a Single Step!' } } }] })
  },
}
</script>

<template>
  <div class="w-full py-8 flex justify-center bg-collection-teal">
    <div class="container flex flex-col lg:flex-row items-center px-12">
      <div class="text-white lg:pr-12 -mb-4 text-center lg:text-left">
        <h2 v-if=" fields.title.value " class="mb-4 text-4xl font-sans-lato font-light">
          {{ fields.title.value }}
        </h2>
        <div class="mb-4" v-html="fields.description.value" />
      </div>
      <div>
        <a :href="fields.link.value?.href" :target="fields.link.value?.target" class="inline-block py-4 px-6 bg-white text-collection-teal text-sm uppercase">
          {{ fields.link.value?.text }}
        </a>
      </div>
    </div>
  </div>
</template>
