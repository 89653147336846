<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  mounted() {
    defineSlice({
      name: { label: 'Title Paragraph', group: 'global' },
      fields: {
        title: { group: 'Title', type: 'text', label: 'Title', editorLabel: true, enabled: true },
        // titleSize came from devise one so i'm leaving the name alone
        titleSize: { group: 'Title', type: 'select', label: 'Title Tag', options: { h1: 'h1', h2: 'h2', h3: 'h3', h4: 'h4', h5: 'h5', h6: 'h6' }, default: 'h3' },
        titleDisplaySize: {
          type: 'select',
          label: 'Title Size',
          group: 'Title',
          options: {
            'text-sm': 'Small',
            'text-base': 'Base',
            'text-lg': 'Large',
            'text-xl': 'XL',
            'text-2xl': '2XL',
            'text-3xl': '3XL',
            'text-4xl': '4XL',
            'text-5xl': '5XL',
          },
        },
        pretitle: { type: 'text', label: 'Pre-title', enabled: false },
        subtitle: { type: 'text', label: 'Sub-title', enabled: false },
        introduction: { type: 'wysiwyg', label: 'Introduction' },
        introductionSize: { type: 'select', label: 'Introduction Size', options: { 'text-sm': 'Small', 'text-base': 'Base', 'text-lg': 'Large', 'text-xl': 'XL', 'text-2xl': 'XXL' }, default: 'text-base' },
        narrow: { type: 'checkbox', label: 'Narrow Paragraph', default: false },
        twoColumns: { type: 'checkbox', label: 'Title and Description in columns?', default: false },
        position: { type: 'select', label: 'Position', options: { left: 'Left', center: 'Center', right: 'Right' }, default: 'center' },
      },
      slots: [],
    })
  },
}
</script>

<template>
  <div>
    <div
      class="flex title-paragraph relative" :class="{
        'justify-center': fields.position.value === 'center',
        'justify-start': fields.position.value === 'left',
        'justify-end': fields.position.value === 'right',
      }"
    >
      <div
        class="py-8 p-8 md:p-12 container mx-auto grid gap-8 relative z-10"
        :class="[fields.position.value, {
          'md:grid-cols-2': fields.twoColumns.value,
          'justify-center': fields.position.value === 'center',
          'justify-start': fields.position.value === 'left',
          'justify-end': fields.position.value === 'right',
        }]"
      >
        <div
          v-if="fields.title.enabled"
          class="title flex justify-center flex-col"
          :class="{
            'max-w-[500px]': fields.narrow.value,
            'items-center text-center': fields.position.value === 'center',
            'items-start text-left': fields.position.value === 'left',
            'items-end text-right': fields.position.value === 'right',
          }"
        >
          <VoixWysiwyg
            class="font-bold uppercase mb-2 text-xs"
            :field="fields.pretitle"
          />
          <component
            v-if="fields.title.value"
            :is="fields.titleSize.value || 'h1'"
            :class="fields.titleDisplaySize.value ?? ''"
          >
            <VoixWysiwyg :field="fields.title" />
          </component>
          <VoixWysiwyg
            class="font-bold uppercase mb-2 text-base"
            :field="fields.subtitle"
          />
        </div>
        <VoixWysiwyg
          v-if="fields.introduction.value"
          class="prose-p:mb-3 flex items-center font-light"
          :class="[fields.introductionSize.value, {
            'max-w-[500px]': fields.narrow.value,
            'justify-center text-center': fields.position.value === 'center',
            'justify-start text-left': fields.position.value === 'left',
            'justify-end text-right': fields.position.value === 'right',
          }]"
          :field="fields.introduction"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  @apply text-4xl
}
h2 {
  @apply text-3xl
}
h3 {
  @apply text-2xl
}
h4 {
  @apply text-xl
}
h5 {
  @apply text-lg
}
h6 {
  @apply text-base
}
</style>
