<script>
export default {
  props: {
    fields: {
      type: Object,
      default: () => ({}),
    },
    slice: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    defineSlice({
      name: { label: 'Two Col Container', group: 'global' },
      preview: 'SlicesGlobalContainersTwoColContainer.jpg',
      slots: [{ name: 'default', allowedElements: [] }],
    })
  },
}
</script>

<template>
  <div class="flex justify-center">
    <div class="p-8 md:p-12 container mx-auto">
      <div class="grid grid-cols-2 gap-4">
        <slot />
      </div>
    </div>
  </div>
</template>
